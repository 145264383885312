import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
} from "@material-tailwind/react";

export default function ConfigEmployees(): JSX.Element {
  const token = localStorage.getItem("token");

  interface CreateNewEmployee {
    firstname: string;
    lastname: string;
    uname: string;
    password: string;
    role: string;
    phoneNumber: string;
    zone: string;
  }

  const initialNewEmployee: CreateNewEmployee = {
    firstname: "",
    lastname: "",
    uname: "",
    password: "",
    role: "agent",
    phoneNumber: "",
    zone: "",
  };

  const roleMap = [
    {
      key: "agent",
      label: "ตัวแทนขาย",
    },
    {
      key: "superAdmin",
      label: "สิทธิ์สูงสุด",
    },
    {
      key: "admin",
      label: "สิทธ์ admin",
    },
  ];

  const [newEmployee, setNewEmployee] =
    useState<CreateNewEmployee>(initialNewEmployee);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  function inputValue(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: string
  ): void {
    setNewEmployee((prevData) => ({ ...prevData, [key]: e.target.value }));
  }

  function toggleShowPassword() {
    setShowPassword((prevShow) => !prevShow);
  }

  function createNewEmployeeUser() {
    try {
      axios
        .post(
          `${process.env.REACT_APP_ADMIN_API}/action/accountCreation`,
          {
            firstName: newEmployee.firstname,
            lastName: newEmployee.lastname,
            uname: newEmployee.uname,
            password: newEmployee.password,
            role: newEmployee.role,
            phoneNumber: newEmployee.phoneNumber,
            zone: newEmployee.zone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          Swal.fire(`${res.data.message}`, "", "success");
        })
        .catch((err) => {
          Swal.fire(`${err.response.data.Error}`, "", "error");
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function handleRole(newRole: string) {
    setNewEmployee({ ...newEmployee, role: newRole });
  }

  return (
    <main className="w-full h-full mt-24 p-2.5 md:p-0">
      <div className="w-full mx-auto border rounded-xl p-2.5 md:w-9/12">
        <img
          className="w-40 h-40 mx-auto mt-2.5"
          src={`https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official.png`}
          alt="profile_image_user"
        />

        <span className="text-xl">เพิ่มบัญชีพนักงาน</span>

        <div className="w-full h-auto mt-2.5">
          <div className="grid grid-cols-12 items-center w-full h-auto p-2.5 gap-2.5">
            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">ชื่อ </span>
              <input
                type="text"
                placeholder="สมชาย"
                onChange={(e) => inputValue(e, "firstname")}
                className="bg-slate-50 border rounded-full p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">นามสกุล</span>
              <input
                placeholder="มีเงิน"
                type="text"
                onChange={(e) => inputValue(e, "lastname")}
                className="bg-slate-50 border rounded-full p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">ชื่อผู้ใช้งาน (username)</span>
              <input
                placeholder="Manday"
                type="text"
                onChange={(e) => inputValue(e, "uname")}
                className="bg-slate-50 border rounded-full p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">รหัสผ่าน (Password)</span>
              <div className="relative w-full">
                <input
                  placeholder="รหัสผ่าน"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => inputValue(e, "password")}
                  className="bg-slate-50 border rounded-full p-2.5 w-full outline-none h-12"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-4 flex items-center"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <img src="https://cdn-icons-png.flaticon.com/512/591/591192.png" className="h-5 w-5 text-gray-500" />
                  ) : (
                    <img src="https://cdn-icons-png.flaticon.com/512/11502/11502607.png" className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">เบอร์โทรศัพท์</span>
              <input
                placeholder="เบอร์โทรศัพท์"
                type="text"
                onChange={(e) => inputValue(e, "phoneNumber")}
                className="bg-slate-50 border rounded-full p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">โซน</span>
              <select
                defaultValue={newEmployee.zone}
                onChange={(e) => inputValue(e, "zone")}
                className="p-2.5 border rounded-full w-full bg-white"
              >
                <option value="">Select Zone</option>
                <option value="CR">CR</option>
                <option value="NR">NR</option>
                <option value="NER">NER</option>
                <option value="ER">ER</option>
                <option value="WR">WR</option>
              </select>
            </div>

            <div className="flex flex-col col-span-12 w-full mt-4 md:col-span-12">
              <span className="mr-2.5">บทบาท</span>
              <div className="flex h-[49px] w-full">
                {roleMap.map((data) => {
                  return (
                    <div
                      key={data.key}
                      className={`flex w-full h-full bf-slate-50 rounded-full  ${
                        newEmployee.role === data.key
                          ? "bg-postperty text-white"
                          : ""
                      }`}
                      onClick={() => handleRole(data.key)}
                    >
                      <span className="mx-auto flex h-full justify-center items-center text-center cursor-pointer">
                        {String(data.label)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="grid grid-cols-12 gap-x-2.5 col-span-12 w-full h-full">
              <Card className="my-2.5 w-full col-span-4 border border-blue-500">
                <CardBody>
                  <h1 className="mb-2 font-bold">ตัวแทนขาย</h1>
                  <p>{`pending`}</p>
                </CardBody>
              </Card>

              <Card className="my-2.5 w-full col-span-4 border border-blue-500">
                <CardBody>
                  <h1 className="mb-2 font-bold">สิทธิ์สูงสุด</h1>
                  <p>{`สามารถทำได้ทุกอย่างในระบบ `}</p>
                </CardBody>
              </Card>

              <Card className="my-2.5 w-full col-span-4 border border-blue-500">
                <CardBody>
                  <h1 className="mb-2 font-bold">สิทธ์ admin</h1>
                  <p>{`ทำได้ทุกอย่าง แต่กระจายค่าคอมฯไม่ได้  `}</p>
                </CardBody>
              </Card>
            </div>

            <div
              className="col-span-12 bg-postperty h-12 rounded-full flex justify-center items-center text-white cursor-pointer"
              onClick={() => createNewEmployeeUser()}
            >
              ยืนยัน
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
