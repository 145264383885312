import axios from "axios";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Swal from "sweetalert2";

export default function ApprovedMoneyForm(): JSX.Element {
  interface MoneyApprovedForm {
    slip_image: File[];
    document_image: File[];
  }

  const initMoneyApprovedForm = {
    slip_image: [],
    document_image: [],
  };

  const [dataUpload, setDataUpload] = useState<MoneyApprovedForm>(
    initMoneyApprovedForm
  );
  const [thumbnailMainIndex, setThumbnailMainIndex] = useState<number | null>(
    null
  );
  const [thumbnailMain, setThumbnailMain] = useState<string | null>(null);

  const [thumbnailDocumentIndex, setThumbnailDocumentIndex] = useState<
    number | null
  >(null);
  const [thumbnailDocument, setThumbnailDocument] = useState<string | null>(
    null
  );
  const token = localStorage.getItem(`token`);
  const withdraw_id = localStorage.getItem("transactionWithDraw");
  const user_uid = localStorage.getItem("transactionUserUID");
  function handleChangeImageMain(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files) {
      const newImages = Array.from(files);
      // Append the new images to the existing array
      setDataUpload((prevData) => ({
        ...prevData,
        slip_image: [...prevData.slip_image, ...newImages],
      }));

      // Display the thumbnail for the first image
      if (newImages.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setThumbnailMainIndex(0);
          setThumbnailMain(reader.result as string);
        };
        reader.readAsDataURL(newImages[0]);
      }
    }
  }

  function handleChangeDocument(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files) {
      const newImages = Array.from(files);
      // Append the new images to the existing array
      setDataUpload((prevData) => ({
        ...prevData,
        document_image: [...prevData.document_image, ...newImages],
      }));

      // Display the thumbnail for the first image
      if (newImages.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setThumbnailDocumentIndex(0);
          setThumbnailDocument(reader.result as string);
        };
        reader.readAsDataURL(newImages[0]);
      }
    }
  }

  function handleThumbnailToMainDisplay(image: string) {
    setThumbnailMain(image);
  }

  function handleThumbnailDocument(image: string) {
    setThumbnailDocument(image);
  }

  async function handleDeleteSlip_image(index: number, imageUrl: string) {
    await setDataUpload((prevData) => {
      const updatedImages = [...prevData.slip_image];
      updatedImages.splice(index, 1);

      const newState = { ...prevData, slip_image: updatedImages };

      // Check if the deleted index is the current thumbnail index
      if (index === thumbnailMainIndex) {
        // Check if there are images left
        if (newState.slip_image.length > 0) {
          // Set the new thumbnail
          const newThumbnail = URL.createObjectURL(
            newState.slip_image[0]
          );
          setThumbnailMain(newThumbnail);
          setThumbnailMainIndex(0);
        } else {
          // No images left, reset thumbnail
          setThumbnailMain(null);
          setThumbnailMainIndex(null);
        }
      }
      return newState;
    });
  }

  async function handleDeleteDocument_Image(
    index: number,
    imageUrl: string
  ) {
    await setDataUpload((prevData) => {
      const updatedImages = [...prevData.document_image];
      updatedImages.splice(index, 1);

      const newState = { ...prevData, document_image: updatedImages };

      // Check if the deleted index is the current thumbnail index
      if (index === thumbnailDocumentIndex) {
        // Check if there are images left
        if (newState.document_image.length > 0) {
          // Set the new thumbnail
          const newThumbnail = URL.createObjectURL(
            newState.document_image[0]
          );
          setThumbnailDocument(newThumbnail);
          setThumbnailDocumentIndex(0);
        } else {
          // No images left, reset thumbnail
          setThumbnailDocument(null);
          setThumbnailDocumentIndex(null);
        }
      }
      return newState;
    });
  }

  function handleUploadBankingAdmin()  {
    axios.post(`${process.env.REACT_APP_ADMIN_API}/banking/upload-admin-banking`,{
        slip_image : dataUpload?.slip_image,
        document_image : dataUpload?.document_image,
        withdraw_id : withdraw_id,
        target_uid : user_uid,
        status : "approved"
    },{
        headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type" : "multipart/form-data"
        }
    }).then( async ( res : any)=>{
        console.log(res.data);
        await Swal.fire(`ตัดเงินจาก user : ${user_uid}` , `ทำรายการ ${withdraw_id} สำเร็จ` , "success" );
        window.location.replace("/withdrawlogs")
    }).catch((err)=>{
        console.log(err)
        Swal.fire(``,`${err.response.data.Error}` , "error");
    })
  }
  return (
    <main className="w-full h-full ">
      <h1 className="w-full h-12 text-xl text-center mt-2.5 text-red-500">
        รหัสธุรกรรม : {withdraw_id}
      </h1>
      <div className="w-full overflow-y-auto h-[850px]">
      <div className="relative w-full h-[400px] bg-gray-100 rounded-xl cursor-pointer mt-2.5 md:w-8/12 mx-auto p-2.5">
        <input
          type="file"
          name="images"
          className="w-full h-full border z-50 absolute top-0 left-0 opacity-0 cursor-pointer"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleChangeImageMain(e)}
          multiple
        />
        <h1 className="font-main absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">{`ใส่รูปภาพเอกสารทางบัญชี`}</h1>

        {thumbnailMain && (
          <img
            src={thumbnailMain}
            alt="thumbnail-image"
            className="w-full h-full absolute top-0 rounded-xl object-contain  z-0" // Adjusted z-index
          />
        )}
      </div>

      <div
        className={
          dataUpload.slip_image.length > 0
            ? " w-full h-[100px] overflow-x-auto border flex rounded-xl mt-2.5   mx-auto md:8/12"
            : "hidden"
        }
      >
        {dataUpload.slip_image.map((image, index) => {
          const convertFileToImageURL = URL.createObjectURL(image);
          return (
            <div key={index} className="relative flex-shrink-0 cursor-pointer">
              <img
                className="h-full ml-[2px]"
                src={convertFileToImageURL}
                onClick={() =>
                  handleThumbnailToMainDisplay(convertFileToImageURL)
                }
                alt={`thumbnail-${index}`}
              />

              <IoMdClose
                className="absolute right-2 top-2 bg-white rounded-full w-5 h-5"
                onClick={() =>
                  handleDeleteSlip_image(index, convertFileToImageURL)
                }
              />
            </div>
          );
        })}
      </div>

      <div className="relative w-full h-[400px] bg-gray-100 rounded-xl cursor-pointer mt-2.5 md:w-8/12 mx-auto p-2.5">
        <input
          type="file"
          name="images"
          className="w-full h-full border z-50 absolute top-0 left-0 opacity-0 cursor-pointer"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleChangeDocument(e)}
          multiple
        />
        <h1 className="font-main absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">{`ใส่รูปสลิปการโอนเงิน`}</h1>

        {thumbnailDocument && (
          <img
            src={thumbnailDocument}
            alt="thumbnail-image"
            className="w-full h-full absolute top-0 rounded-xl object-contain  z-0" // Adjusted z-index
          />
        )}
      </div>

      <div
        className={
          dataUpload.document_image.length > 0
            ? " w-full h-[100px] overflow-x-auto border flex rounded-xl mt-2.5   mx-auto md:w-8/12"
            : "hidden"
        }
      >
        {dataUpload.document_image.map((image, index) => {
          const convertFileToImageURL = URL.createObjectURL(image);
          return (
            <div key={index} className="relative flex-shrink-0 cursor-pointer">
              <img
                className="h-full ml-[2px]"
                src={convertFileToImageURL}
                onClick={() => handleThumbnailDocument(convertFileToImageURL)}
                alt={`thumbnail-${index}`}
              />

              <IoMdClose
                className="absolute right-2 top-2 bg-white rounded-full w-5 h-5"
                onClick={() =>
                  handleDeleteDocument_Image(index, convertFileToImageURL)
                }
              />
            </div>
          );
        })}
      </div>


        <div onClick={()=>handleUploadBankingAdmin()} className="w-full mx-auto h-12 bg-postperty rounded-full flex justify-center items-center text-white mt-12 border cursor-pointer  md:w-8/12 ">ยืนยัน</div>

      <div className="w-full h-[300px]" ></div>
      </div>
    </main>
  );
}
