import axios from "axios"

export function Test():JSX.Element{
    function handleControlIOT(command : string){
        axios.post(`http://localhost:3000/${command}`,{}).then((res)=>{
            console.log(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }
    return(
        <div className="bg-white  w-full h-screen flex justify-around items-center">
            <button className="bg-black text-white h-12 rounded-full w-48" onClick={()=>handleControlIOT("ledon")}>เปิดไฟ</button>
            <button className="bg-black text-white h-12 rounded-full w-48" onClick={()=>handleControlIOT("ledoff")}>ปิดไฟ</button>
        </div>
    )
}