import React, { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import MapCarSection from './MapCarSection';
import axios from 'axios';

type XAxisConfig = {
  scaleType: 'band' | 'time' | 'point' | 'log' | 'pow' | 'sqrt' | 'utc' | 'linear';
  dataKey: string;
  label: string;
};

const token = localStorage.getItem("token");

const chartSetting = {
  yAxis: [{ label: 'Lead Count' }],
  width: 900,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const valueFormatter = (value: number | null) => `${value} units`;

const OnsiteOverview: React.FC = () => {
  const [view, setView] = useState<'day' | 'month' | 'year'>('day');
  const [dataLeadStat, setDataLeadStat] = useState<any[]>([]);

  const getDataLeadStat = (type: string) => {
    try {
      axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/onsite/lead/stat`, {
        typeData: type
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        console.log(res.data.data); // Debugging line to check API data
        setDataLeadStat(res.data.data); // Ensure data is set correctly
      }).catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDataLeadStat(view);
  }, [view]);

  const getCurrentData = () => {
    return dataLeadStat;
  };

  const getXAxisKey = (): XAxisConfig => {
    return { scaleType: 'band', dataKey: 'name', label: 'Date' }; // Updated to use 'name' for X-Axis
  };

  return (
    <main className="w-full h-screen">
      <div className="w-full h-full flex flex-col justify-center">
        <div className="grid grid-cols-3 w-full max-h-96 p-2.5 gap-x-2.5">
          <div className="w-full h-full col-span-1 border p-2.5 rounded-xl">
            <PieChart
              series={[
                {
                  data: [
                    { value: 1, label: 'ONSITE 1' },
                    { value: 2, label: 'ONSITE 2' },
                    { value: 3, label: 'ONSITE 3' },
                  ],
                  innerRadius: 30,
                  outerRadius: 100,
                  paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -45,
                  endAngle: 225,
                  cx: 150,
                  cy: 150,
                },
              ]}
              width={400}
              height={300}
            />
          </div>
          <div className="flex justify-center items-center w-full h-full col-span-2 border rounded-xl">
            <BarChart
              dataset={getCurrentData()} // Ensure the correct data is provided
              xAxis={[getXAxisKey()]} // Correct X-Axis key and settings
              series={[
                {
                  dataKey: 'leadCount', // Correct dataKey for Y-axis values
                  label: 'Lead statistics',
                  valueFormatter,
                  color: 'black',
                },
              ]}
              {...chartSetting}
            />
            {/* Menu for selecting the view */}
            <div className="flex flex-col mb-4">
              <button
                onClick={() => setView('day')}
                className="px-4 py-2 mx-2 mb-2.5 bg-black text-white rounded-full"
              >
                7 day 
              </button>
              <button
                onClick={() => setView('month')}
                className="px-4 py-2 mx-2 mb-2.5 bg-black text-white rounded-full"
              >
                this month
              </button>
              <button
                onClick={() => setView('year')}
                className="px-4 py-2 mx-2 bg-black text-white rounded-full"
              >
                12 Month
              </button>
            </div>
          </div>
        </div>
        {/* MapSection component can be added here */}
        <MapCarSection />
      </div>
    </main>
  );
};

export default OnsiteOverview;
