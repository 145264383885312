import React, { useState } from "react";
import { PuffLoader } from "react-spinners";
import axios from "axios";
import Swal from "sweetalert2";
import { motion } from "framer-motion";

export function Login(): JSX.Element {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false); // State to toggle password visibility
    const backgroundImageUrl = "url('https://images.pexels.com/photos/1903702/pexels-photo-1903702.jpeg')";
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    function handleValidatePermission() {
        if (!isUploading) {
            setIsUploading(true);

            axios.post(`${process.env.REACT_APP_ADMIN_API}/auth/login`, {
                uname: username,
                password: password
            }).then((res) => {
                const { Token, permission, profile } = res.data;
                localStorage.setItem("token", Token);
                localStorage.setItem("permission", permission);
                localStorage.setItem("firstname_admin", profile.firstName);
                localStorage.setItem("lastname_admin", profile.lastName);
                localStorage.setItem("username_admin", profile.uname);
                localStorage.setItem("profile_image_admin", profile.profile_image);

                setTimeout(() => {
                    window.location.replace('/');
                }, 5000);
            }).catch((err) => {
                console.error(err);
                setIsUploading(false);
                Swal.fire("เข้าสู่ระบบล้มเหลว", 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง', 'error');
            });
        }
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            handleValidatePermission();
        }
    }

    return (
        <main
            className="w-full h-screen flex justify-center items-center bg-cover bg-center"
            style={{ backgroundImage: backgroundImageUrl }}
        >
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
                className="backdrop-blur-lg bg-white/20 shadow-xl rounded-3xl p-8 md:p-10 w-11/12 max-w-md mx-auto border border-white/30"
            >
                <motion.h1
                    className="text-3xl md:text-4xl font-bold text-center text-blue-600 mb-4"
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.6 }}
                >
                    ระบบบริหารจัดการภายใน POSTPERTY
                </motion.h1>
                <motion.p
                    className="text-center text-white/80 mb-10"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                >
                    บริษัท โพสต์เพอร์ตี้ จำกัด
                </motion.p>

                <div className="space-y-6">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6, duration: 0.6 }}
                    >
                        <label htmlFor="username" className="block text-sm font-medium text-white mb-2">
                            ชื่อผู้ใช้งาน
                        </label>
                        <input
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                            type="text"
                            placeholder="กรอกชื่อผู้ใช้งาน"
                            className="w-full px-4 py-2 rounded-lg bg-white/20 border border-white/30 text-white placeholder-white/70 outline-none focus:ring-2 focus:ring-blue-300"
                        />
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.8, duration: 0.6 }}
                        className="relative"
                    >
                        <label htmlFor="password" className="block text-sm font-medium text-white mb-2">
                            รหัสผ่าน
                        </label>
                        <input
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            type={passwordVisible ? "text" : "password"} // Toggle between text and password
                            placeholder="กรอกรหัสผ่าน"
                            className="w-full px-4 py-2 rounded-lg bg-white/20 border border-white/30 text-white placeholder-white/70 outline-none focus:ring-2 focus:ring-blue-300"
                        />
                        <button
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            className="absolute right-3 top-9"
                            aria-label="Toggle password visibility"
                        >
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/4855/4855030.png"
                                alt="Toggle visibility"
                                className="w-5 h-5 opacity-70 hover:opacity-100 transition-opacity"
                            />
                        </button>
                    </motion.div>

                    <p className="text-center text-sm text-white/70 mt-4">
                        หากลืมรหัสผ่านกรุณาติดต่อผู้ดูแลระบบ
                    </p>

                    <motion.button
                        onClick={handleValidatePermission}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`w-full py-3 mt-6 rounded-lg text-white font-semibold shadow-lg transform transition-all duration-300 ${
                            isUploading ? "bg-gray-400 cursor-not-allowed" : "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500"
                        }`}
                        disabled={isUploading}
                    >
                        {isUploading ? <PuffLoader size={24} color="#fff" /> : "เข้าสู่ระบบ"}
                    </motion.button>
                </div>
            </motion.div>
        </main>
    );
}
