import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

interface DashboardData {
  summary: {
    companyExpenses_notIncludeBotOrUserTestSumary: string;
    totalPointsWaiting_notIncludeBotOrUserTestSumary: string;
  };
  received_money_his: {
    uid: string;
    amount: number;
    cut_fees_and_tax: number;
    withdraw_id: string;
    uname: string;
    profile_image: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  }[];
  userList: {
    uid: string;
    uname: string;
    profile_image: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    point: string;
  }[];
}

const SumaryDashboardMoney = () => {
  const [data, setData] = useState<DashboardData | null>(null);
  const [isReceivedDropdownOpen, setIsReceivedDropdownOpen] = useState(false);
  const [isUserListDropdownOpen, setIsUserListDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<DashboardData>(`${process.env.REACT_APP_ADMIN_API}/commission/dashboard`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (!data) return <div className="text-center">Loading...</div>;

  return (
    <div className="p-4 bg-gray-100 max-h-screen h-full w-full overflow-hidden">
      <div className='w-full h-screen overflow-y-auto'>
        <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          {/* First Dropdown */}
          <div
            className="bg-white rounded-lg shadow p-4 cursor-pointer flex items-center justify-between"
            onClick={() => setIsReceivedDropdownOpen(!isReceivedDropdownOpen)}
          >
            <div>
              <h2 className="text-lg font-semibold mb-2 text-green-500">
                บริษัทจ่ายไปแล้วทั้งหมด
              </h2>
              <p className="text-3xl font-bold">
                {formatNumber(parseFloat(data.summary.companyExpenses_notIncludeBotOrUserTestSumary))} บาท
              </p>
            </div>
            <motion.img
              src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
              alt="dropdown arrow"
              className="w-5 h-5"
              animate={{ rotate: isReceivedDropdownOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Second Dropdown */}
          <div
            className="bg-white rounded-lg shadow p-4 cursor-pointer flex items-center justify-between"
            onClick={() => setIsUserListDropdownOpen(!isUserListDropdownOpen)}
          >
            <div>
              <h2 className="text-lg font-semibold mb-2 text-orange-500">บริษัทรอจ่ายค่าคอมฯให้สมาชิกทั้งหมด</h2>
              <p className="text-3xl font-bold">
                {formatNumber(parseFloat(data.summary.totalPointsWaiting_notIncludeBotOrUserTestSumary))} บาท
              </p>
            </div>
            <motion.img
              src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
              alt="dropdown arrow"
              className="w-5 h-5"
              animate={{ rotate: isUserListDropdownOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            />
          </div>
        </div>

        {/* Dropdown for received money list */}
        <AnimatePresence>
          {isReceivedDropdownOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="bg-white rounded-lg shadow p-4 mt-4 overflow-hidden"
            >
              <h2 className="text-lg font-semibold mb-4">Received Money History</h2>
              <div className="space-y-4">
                {data.received_money_his.map((entry) => (
                  <div key={entry.withdraw_id} className="flex items-center justify-around">
                    <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-300 ml-2.5 mr-2.5">
                      <img src={entry.profile_image} alt={entry.uname} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <p className="font-semibold">{entry.first_name} {entry.last_name}</p>
                      <p className="text-sm text-gray-500">{entry.phone_number}</p>
                    </div>
                    <div className="ml-auto">
                      <p className="font-semibold">{formatNumber(entry.amount)} THB</p>
                      <p className="text-sm text-gray-500">Fees: {formatNumber(entry.cut_fees_and_tax)} THB</p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Dropdown for user list */}
        <AnimatePresence>
          {isUserListDropdownOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="bg-white rounded-lg shadow p-4 mt-4 overflow-hidden"
            >
              <h2 className="text-lg font-semibold mb-4">User List</h2>
              <div className="space-y-4">
                {data.userList.map((user) => (
                  <div key={user.uid} className="flex items-center justify-between p-2">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-300 border border-blue-500">
                        <img src={user.profile_image} alt={user.uname} className="w-full h-full object-cover" />
                      </div>
                      <div className="flex flex-col">
                        <p className="font-semibold">{user.first_name} {user.last_name}</p>
                        <p className="text-sm text-gray-500">{user.phone_number}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-semibold">{user.point} THB</p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SumaryDashboardMoney;
