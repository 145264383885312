import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
export default function EditMember(): JSX.Element {
  const { uid } = useParams();
  const token = localStorage.getItem("token");
  const target_uid = String(uid);
  interface UserData {
    profile_image?: string;
    uname?: string;
    phone_number?: string;
    age?: string;
    gender?: string;
  }

  interface UpdateDataUser {
    target_uid: string;
    age: string | null;
    uname: string | null;
    resetProfileImage: string;
    phoneNumber: string | null;
    gender: string | null;
    role: string;
    bio: string | null;
    confirmPassword: string;
  }
  interface UserPost {
    title: string;
    description: string;
    id: string;
    AssetImagesURL: string[];
    ban : boolean;
    hidden : boolean;
  }
  
  const [initUserData, setInitUserData] = useState<UserData>();
  const [userPost, setUserPost] = useState<UserPost[]>([]);

  const formUpdateDataInitial: UpdateDataUser = {
    target_uid: target_uid,
    age: null,
    uname: null, // Corrected property to uname
    resetProfileImage: "false",
    phoneNumber: null,
    gender: null,
    role: "default",
    bio: null,
    confirmPassword: "",
  };

  const [dataUpdated, setDataUpdated] = useState<UpdateDataUser>(
    formUpdateDataInitial
  );


  function getUserPosts() : void {
    axios.post(`${process.env.REACT_APP_ADMIN_API}/fetchData/userPosts` ,{
      uid : target_uid
    },{
      headers : {
        Authorization : `Bearer ${token}`
      }
    }).then((res)=>{
      setUserPost(res.data);
      console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const genderMap = [
    {
      gender: "male",
      label: "ชาย",
    },
    {
      gender: "female",
      label: "หญิง",
    },
    {
      gender: "lgbtq",
      label: "ไม่ระบุ",
    },
  ];

  const roleMap = [
    {
      key: "agent",
      label: "ตัวแทนขาย",
    },
    {
      key: "member",
      label: "สมาชิก",
    },
  ];

  function inputValue(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ): void {
    setDataUpdated((prevData) => ({ ...prevData, [key]: e.target.value }));
  }

  useEffect(() => {
    getSpecificUser();
  }, []);

  useEffect(()=>{
    getUserPosts();
  },[])
  function getSpecificUser() {
    axios
      .get(
        `${process.env.REACT_APP_ADMIN_API}/fetchData/specificUser?target_uid=${uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        const userData = res.data.userData;
        setInitUserData(userData);
        console.log(res.data.userData);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire(`${err.response.data.Error}`, "", "error");
      });
  }

  function editUser() {
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_API}/manage/editUser`,
        {
          target_uid: uid,
          age: dataUpdated.age ?? initUserData?.age,
          uname: dataUpdated.uname ?? initUserData?.uname,
          resetProfileImage: dataUpdated.resetProfileImage ?? "false",
          phoneNumber: dataUpdated.phoneNumber ?? initUserData?.phone_number,
          gender: dataUpdated.gender ?? initUserData?.gender,
          role: dataUpdated.role ?? "default",
          bio: dataUpdated.bio ?? "",
          adminpassword: dataUpdated?.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire(`${res.data.Success}`, "", "success");
        getSpecificUser();
      })
      .catch((err) => {
        Swal.fire(`ไม่สำเร็จ`, `${err.response.data.Error}`, "error");
        console.log(err);
      });
  }

  function checkPassword(): void {
    const username = localStorage.getItem("username_admin");
    axios
      .post(`${process.env.REACT_APP_ADMIN_API}/auth/login`, {
        uname: username,
        password: dataUpdated.confirmPassword,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire("Login Failed", " Password incorrect.", "error");
      });
  }

  function handleUpdateDataUser(): void {
    // checkPassword()
    editUser();
  }

  function handleChangeGender(gen: string) {
    setInitUserData({ ...initUserData, gender: gen });
    setDataUpdated({ ...dataUpdated, gender: gen });
  }

  function handleNavigateToAsset(id : string) {
    window.open(`https://www.postperty.com/product/${id}`, "_blank");
}

  return (
    <main className="w-full h-full mt-24 p-2.5 md:p-0">
      <div className="w-full mx-auto  border rounded-xl md:w-9/12">
        <span className="text-xl">แก้ไขโปรไฟล์ User</span>
        <img
          className="w-40 h-40 mx-auto rounded-full mt-2.5"
          src={`${initUserData && initUserData.profile_image}`}
          alt="profile_image_user"
        />

        {/* <div className="flex flex-col col-span-12 w-full  mt-4  md:col-span-6">
          <span className="mr-2.5">บทบาท</span>
          <div className="flex h-[49px] w-full border border-red-500">
            {roleMap.map((data) => {
              return (
                <div className="flex justify-center items-center w-full h-full bf-slate-50 border cursor-pointer">
                  {String(data.label)}
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="w-full h-auto mt-2.5 border rounded-xl">
          <div className="grid grid-cols-12  items-center  w-full h-auto p-2.5 gap-2.5">
            <div className="col-span-12 w-full rounded-md md:col-span-6">
              <span className="mr-2.5">ชื่อผู้ใช้งาน </span>
              <input
                defaultValue={`${initUserData?.uname || ""}`}
                onChange={(e) => inputValue(e, "uname")}
                type="text"
                className=" bg-slate-50 border rounded-full  p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6">
              <span className="mr-2.5">เบอร์โทรศัพท์</span>
              <input
                defaultValue={`${initUserData?.phone_number || ""}`}
                onChange={(e) => inputValue(e, "phoneNumber")}
                placeholder="phone-number"
                type="text"
                className=" bg-slate-50 border   p-2.5 w-full outline-none h-12  rounded-full"
              />
            </div>

            <div className="col-span-12 w-full md:col-span-6  ">
              <span className="mr-2.5">อายุ</span>
              <input
                defaultValue={`${initUserData?.age || ""}`}
                onChange={(e) => inputValue(e, "age")}
                placeholder="อายุ"
                type="text"
                className=" bg-slate-50 border rounded-sm p-2.5 w-full outline-none h-12"
              />
            </div>

            <div className="flex flex-col col-span-12 w-full  md:col-span-6  justify-center ">
              <span className="mr-2.5">เพศ</span>
              <div className="flex h-[49px] w-full">
                {genderMap.map((gen) => (
                  <div
                    key={gen.label}
                    onClick={() => handleChangeGender(gen.gender)}
                    className={`flex border   justify-center text-center flex-col w-full h-12 bf-slate-50 cursor-pointer rounded-full ${
                      gen.gender === initUserData?.gender
                        ? "bg-postperty text-white"
                        : ""
                    }`}
                  >
                    {String(gen.label)}
                  </div>
                ))}
              </div>
            </div>

            <div className="col-span-12 w-full  h-12">
              <input
                onChange={(e) => inputValue(e, "confirmPassword")}
                placeholder="ยืนยันรหัสผ่าน"
                type="password"
                className=" bg-slate-50 border  p-2.5 w-full outline-none h-12 rounded-full"
              />
            </div>

            <div className="col-span-12 grid grid-cols-4  h-[500px] w-full overflow-y-auto ">
              {userPost.map((user)=>{
                return (
                  <div className="relative  w-full h-full flex flex-col justify-center items-center" onClick={()=>handleNavigateToAsset(user?.id)}>
                <img
                  className="aspect-square	cursor-pointer"
                  src={user.AssetImagesURL[0]}
                  alt={user.id}
                />
                <h1>{user.title}</h1>
                <span className="text-red-500 w-full text-center border border-red-500 mx-auto">{user?.ban ? "แบบเรียบร้อย" : null}</span>
                {user?.hidden ? <img src="https://cdn-icons-png.flaticon.com/512/10795/10795957.png" className="z-50 w-8 h-8 absolute top-36 left-1/2 transform -translate-x-1/2 "/> : null}
              </div>
                )
              })}
            
          
            </div>

            <div
              className="col-span-12 bg-postperty h-12 rounded-full flex justify-center items-center text-white cursor-pointer"
              onClick={() => handleUpdateDataUser()}
            >
              ยืนยันการแก้ไข
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
