import React from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Alert,
} from "@material-tailwind/react";
import {
    GlobeAltIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    CalendarDaysIcon,
    PowerIcon,
    CommandLineIcon,
    ChatBubbleBottomCenterIcon,
    WalletIcon,
    UserIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import {
    ChevronDownIcon,
    CubeTransparentIcon,
} from "@heroicons/react/24/outline";
import "../index.css"
import { Link } from "react-router-dom";
import { useSidebar } from '../context/SidebarContext';
import { useAuth } from "../auth/AuthControl";
import { Login } from "../components/auth/login/Login";
export function SidebarWithLogo() {

    const {isAuthenticated , login , logout} = useAuth()

  
    const { open, setOpen } = useSidebar();
    const [openAlert, setOpenAlert] = React.useState(true);
    console.log('SidebarWithLogo is rendering');


    const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
    }
    const currentUrl = window.location.href;

    const hiddenURLs = ['http://localhost:3000/auth/login', "https://sudo.postperty.com/auth/login"]
    const fName =  localStorage.getItem("firstname_admin")
   const lName =    localStorage.getItem("lastname_admin")
   const uName =   localStorage.getItem("username_admin")
   const profileIMG =  "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official.png"




    return (
        <Card className={hiddenURLs.includes(currentUrl) ? "hidden" : " relative h-[calc(100vh)]   w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5  hidden md:flex z-50"}>
            
            <div className=" flex flex-col items-center justify-center  absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full text-black border  p-2.5">

                <img src={profileIMG ? profileIMG : ""} className="w-14 h-14" alt="profile-admin"/>
            <h1>{uName}</h1>
                <h1>{fName} {lName}</h1>
            </div>
            <div className="mb-2 flex items-center gap-4 p-4">
                <img src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official.png" alt="brand" className="h-8 w-8" />
                <Typography variant="h5" color="blue-gray" className="font-main">
                    บริษัท โพสต์เพอร์ตี้
                </Typography >
            </div>
            <List>
                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <GlobeAltIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal font-main">
                                Website
                            </Typography >
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">

                        <List className="p-0  ml-2.5">
                            <Link to={`/`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <CommandLineIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    หน้าหลัก
                                    <ListItemSuffix>
                                        <Chip value="5 รายการ" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>

                        <List className="p-0  ml-2.5">
                            <Link to={`/lead`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <CalendarDaysIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Lead
                                    <ListItemSuffix>
                                        <Chip value="x" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>

                        <List className="p-0  ml-2.5">
                            <Link to={`/postperty/help`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <ChatBubbleBottomCenterIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    ช่วยขาย (Help Post)
                                    <ListItemSuffix>
                                        <Chip value="x" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>

                        <List className="p-0  ml-2.5">
                            <Link to={`/assets`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <WalletIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    โพสต์ทั้งหมด (Post)
                                    <ListItemSuffix>
                                        <Chip value="x" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>



                        <List className="p-0  ml-2.5">
                            <Link to={`/members`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <UserIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    สมาชิกทั้งหมด (Members)
                                    <ListItemSuffix>
                                        <Chip value="x" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>


                        <List className="p-0  ml-2.5">
                            <Link to={`/reports`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <ShieldCheckIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    แจ้งปัญหา (Report)
                                    <ListItemSuffix>
                                        <Chip value="x" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                    </ListItemSuffix>

                                </ListItem>
                            </Link>
                        </List>



                    </AccordionBody>
                </Accordion>

                <hr className="my-2 border-blue-gray-50" />
                <ListItem>
                    <ListItemPrefix>
                        <InboxIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Inbox
                    <ListItemSuffix>
                        <Chip value="14" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                    </ListItemSuffix>
                </ListItem>
                <ListItem>
                    <ListItemPrefix>
                        <UserCircleIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Profile
                </ListItem>
                <ListItem>
                    <ListItemPrefix>
                        <Cog6ToothIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Settings
                </ListItem>
                <ListItem onClick={()=>logout()}>
                    <ListItemPrefix>
                        <PowerIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Log Out
                </ListItem>
            </List>
            {/* <Alert open={openAlert} className="mt-auto" onClose={() => setOpenAlert(false)}>
                <CubeTransparentIcon className="mb-4 h-12 w-12" />
                <Typography variant="h6" className="mb-1">
                    Upgrade to PRO
                </Typography >
                <Typography variant="small" className="font-normal opacity-80">
                    Upgrade to Material Tailwind PRO and get even more components, plugins, advanced features
                    and premium.
                </Typography >
                <div className="mt-4 flex gap-3">
                    <Typography
                        as="a"
                        href="#"
                        variant="small"
                        className="font-medium opacity-80"
                        onClick={() => setOpenAlert(false)}
                    >
                        Dismiss
                    </Typography >
                    <Typography as="a" href="#" variant="small" className="font-medium">
                        Upgrade Now
                    </Typography >
                </div>
            </Alert> */}
        </Card>
    );
}