import React from "react";

// Define an interface for the car data
interface Car {
  imageUrl: string;
  license: string;
}

// Define an interface for the profile data
interface Profile {
  imageUrl: string;
  fullName: string;
  zone: string;
  status: string;
}

// Sample array of cars with their licenses
const cars: Car[] = [
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-midnight-gray.png",
    license: "LICENSE CAR MNOQ23",
  },
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-white-storm.png",
    license: "LICENSE CAR ABC123",
  },
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-sky-blue.png",
    license: "LICENSE CAR XYZ789",
  },
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-midnight-gray.png",
    license: "LICENSE CAR MNOQ23",
  },
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-white-storm.png",
    license: "LICENSE CAR ABC123",
  },
  {
    imageUrl: "https://www.neta.co.th/assets/img/product/pic-color-sky-blue.png",
    license: "LICENSE CAR XYZ789",
  },
  // Add more car objects as needed
];

// Sample array of profiles
const profiles: Profile[] = [
  {
    imageUrl: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/4.png",
    fullName: "F_XXXXX L_XXXXXX",
    zone: "ZONE : XXXX",
    status: "STATUS : ONLINE",
  },
  {
    imageUrl: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/5.png",
    fullName: "F_YYYYY L_YYYYYY",
    zone: "ZONE : YYYY",
    status: "STATUS : OFFLINE",
  },
  {
    imageUrl: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/6.png",
    fullName: "F_ZZZZZ L_ZZZZZZ",
    zone: "ZONE : ZZZZ",
    status: "STATUS : BUSY",
  },

  // Add more profile objects as needed
];

const MapCarSection: React.FC = () => {
  return (
    <div className="grid grid-cols-2 items-center w-full h-full ">
      <div className="w-full h-full flex justify-center items-center">
        <h1>REALTIME LOCATION</h1>
      </div>
      <div className="w-full h-[60vh] grid grid-cols-2">
        <div className="flex flex-col items-center w-full max-h-full overflow-y-auto space-y-4">
          {profiles.map((profile, index) => (
            <div
              key={index}
              className="flex w-full h-24 bg-gray-100 rounded-xl p-2"
            >
              <img
                className="w-auto h-full rounded-xl"
                src={profile.imageUrl}
                alt={`profile-${index}`}
              />
              <div className="flex flex-col w-full h-full justify-center pl-4">
                <h1>{profile.fullName}</h1>
                <p>{profile.zone}</p>
                <p>{profile.status}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-11/12 mx-auto h-full max-h-full overflow-y-auto bg-gray-100 p-2.5 rounded-xl">
          {cars.map((car, index) => (
            <div
              key={index}
              className="w-full h-auto flex justify-center items-center my-2"
            >
              <img className="max-h-24" src={car.imageUrl} alt={`car-${index}`} />
              <h1>{car.license}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapCarSection;
