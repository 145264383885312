import React from "react"
import {
    CardHeader,
    CardBody,
    Typography,
    Button,
    Dialog,
    DialogHeader,
    DialogFooter,
} from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { LuListFilter } from "react-icons/lu"
import { MdOutlineKeyboardArrowUp } from "react-icons/md"
import { PuffLoader } from "react-spinners";
import MetaDecorator from "../../utility/MetaDecorator";

export function LongDialog() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(!open);

    return (
        <div className=" absolute top-4  left-4">
            <div className="bg-gray-100 w-36 h-12 text-xl flex justify-center items-center rounded-full cursor-pointer hover:bg-black hover:text-white" onClick={handleOpen}><LuListFilter /></div>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader className="font-main">กรองอสังหาฯ (Filter Assets)</DialogHeader>
                {/* <DialogBody className="h-[42rem] overflow-y-auto">
                    <Typography className="font-main">

                    </Typography>
                </DialogBody> */}
                <DialogFooter className="space-x-2">
                    <Button variant="text" color="blue-gray" onClick={handleOpen}>
                        cancel
                    </Button>
                    <Button variant="gradient" className="bg-balck" onClick={handleOpen}>
                        confirm
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}
export function PostAssets() {
    interface Asset {
        id: string;
        images_url: {
            AssetImagesURL: string[];
        };
        title: string;
        description: string;

        user_data: {
            user: {
                profile_image: string;
                uname: string;
            }
        }

        sold : boolean
    }

    const containerRef = useRef<HTMLDivElement>(null);

    let page = 1
    const [assetsData, setAssetsData] = useState<Asset[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchingData, setFetchingData] = useState<boolean>(false);

    function getAssets() {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API}/asset/getMultipleAsset?page=${page}`).then((res) => {
            const assets :Asset[] = res.data.assets;
            setAssetsData((prevData)=>[...prevData, ...assets])
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }
    
    

    function handleScroll() {
        const container = containerRef.current!;
        const bottomThreshold = 20; // Set your desired threshold
        setLoading(true);
      
        if (
          container.scrollHeight - container.scrollTop <= container.clientHeight + bottomThreshold &&
          !loading &&
          !fetchingData
        ) {
          setFetchingData(true);
          if(fetchingData){
            setTimeout(() => {
              }, 2500);
          }
          console.log('current page', page);
          page += 1;
          console.log('after page', page);
          setTimeout(() => {
            getAssets(); // Fetch new data
          }, 3000);
        }
      }
      



    useEffect(() => {
        getAssets();
        // Attach scroll event listener when the component mounts
        const container = containerRef.current!;
        container.addEventListener('scroll', handleScroll);
        return () => {
            // Detach the event listener when the component unmounts
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        console.log("Clicked!");
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };


    const handleNavigateToAsset = (asset_id: string) => {
        window.open(`https://postperty.com/product/${asset_id}`, '_blank');
      };
      

    return (
        <div className="relative w-full h-full  flex rounded">
            {/* <MetaDecorator
             title="test from engineer arm"
             description="test for dynamic image meta tag"
             imageUrl="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/asset/house/houseimages/postperty-2024-04-09_52d947e1-3dd9-45f1-9aaa-2ca133786f23.jpg"
             imageAlt="image of postperty officla"
            /> */}
            <LongDialog />
            <div className="absolute bottom-10 right-12 text-5xl bg-gray-100 z-50 rounded-full cursor-pointer hover:bg-black hover:text-white" onClick={() => handleScrollToTop()} >
                <MdOutlineKeyboardArrowUp />
            </div>
            <div
                ref={containerRef}
                className="grid grid-cols-12 gap-x-2 flex-wrap h-[890px] overflow-y-auto w-full mt-16"
            >
                {assetsData.map((asset: Asset, index: number) => {
                    const ID = asset?.id + index;
                    const imageUrl = asset?.images_url?.AssetImagesURL?.[0] ?? '';
                    const title = asset?.title;
                    const descr = asset?.description;
                    const u_name = asset?.user_data?.user.uname;
                    const asset_ID = asset?.id
                    const soldOut = asset?.sold
                    return (
                        <motion.div
                            className="relative mt-6 w-90 col-span-12 cursor-pointer hover:bg-gray-100 hover:rounded-xl md:col-span-3"
                            key={ID}
                            whileHover={{ scale: 1.05 }} // Scale on hover
                            onClick={()=>handleNavigateToAsset(asset_ID)}
                        >
                            <CardHeader className="relative border border-blue-500 h-56   bg-black  mt-6">
                            {soldOut ?  <img
                                    src={"https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/SOLD%20OUT.svg"}
                                    alt="asset image"
                                    className="absolute w-full  h-full z-50  object-cover"
                                    loading="lazy"
                                /> : null}
                                <img
                                    src={imageUrl}
                                    alt="asset image"
                                    className="w-full  h-full object-contain z-40"
                                    loading="lazy"
                                />

                               

                            </CardHeader>
                            <CardBody>
                                <Typography variant="h5" color="blue-gray" className="mb-2 font-main">
                                    {title.slice(0, 30)}
                                </Typography>
                                <Typography className="font-main">
                                    {`${descr.slice(0, 115)}..`}
                                </Typography>
                            </CardBody>
                            <div className="pt-0 absolute right-6    top-8">
                                <Button className="bg-postperty">{u_name.slice(0, 20)}</Button>
                            </div>
                        </motion.div>
                    );
                })}
                {loading && <p className=" w-full flex justify-center items-center col-span-12 font-main mt-2.5 h-12  h-screen">{loading ? <PuffLoader color="#000" /> : null}</p>}
            </div>
        </div>
    );
}
