import React, { useState, useCallback, useEffect } from "react";
import {
  Home,
  Users,
  MapPin,
  Car,
  Bath,
  Bed,
  Phone,
  BedDouble,
  HomeIcon,
  ChevronLeft,
  ChevronRight,
  Search,
} from "lucide-react";
import axios from "axios";

// Types
interface EstateData {
  id: string;
  type: string;
  title: string;
  description: string;
  sell_price: string;
  images_url: {
    Preview: string[];
    BackDeedsURL: string[];
    FrontDeedsURL: string[];
    AssetImagesURL: string[];
  };
}

interface CommissionMember {
  reason: string;
  amount: number;
  date: string;
  uid: string;
  assetid_soldout: string;
}

interface ApiResponse {
  estate_data: EstateData;
  listMember: CommissionMember[];
  message: string;
}

type Highlight = {
  icon: JSX.Element;
  label: string;
  value: string;
};

interface PropertyListing {
  assetid_soldout: string;
  id: string;
  type: string;
  title: string;
  description: string;
  sell_price: string;
  contact: {
    PhoneNumber: string;
    Line: string;
  };
  location: {
    string: object;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
}

// API Service Function
const fetchRealEstateData = async (assetId: string): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("No authentication token found");
  }

  const response = await axios.post<ApiResponse>(
    `${process.env.REACT_APP_ADMIN_API}/commission/checkBackRealEstate`,
    { asset_id: assetId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

// Main Component
const CheckBackRealEstate: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [assetId, setAssetId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<ApiResponse | null>(null);
  const [listRealEstateSoldOut, setListRealEstateSoldOut] = useState<
    PropertyListing[]
  >([]);

  const handleSearch = useCallback(async () => {
    if (!assetId) {
      setError("Please enter an Asset ID");
      return;
    }

    try {
      setLoading(true);
      setError("");
      const responseData = await fetchRealEstateData(assetId);
      setData(responseData);
      setCurrentImageIndex(0); // Reset image index when new data is loaded
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [assetId]);

  const nextImage = useCallback(() => {
    if (!data) return;
    const imagesLength = data.estate_data.images_url.AssetImagesURL.length;
    setCurrentImageIndex((prev) => (prev + 1) % imagesLength);
  }, [data]);

  const prevImage = useCallback(() => {
    if (!data) return;
    const imagesLength = data.estate_data.images_url.AssetImagesURL.length;
    setCurrentImageIndex((prev) => (prev - 1 + imagesLength) % imagesLength);
  }, [data]);

  function fetchRealEstateListSoldOut() {
    axios
      .get(
        `${process.env.REACT_APP_ADMIN_API}/commission/listsRealEstateSoldout`
      )
      .then((res) => {
        console.log(res.data);
        setListRealEstateSoldOut(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchRealEstateListSoldOut();
  }, []);
  const formatPrice = useCallback((price: number | string): string => {
    return new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(price));
  }, []);

  const highlights: Highlight[] = [
    { icon: <Bed size={20} />, label: "ห้องนอน", value: "3 ห้อง" },
    { icon: <Bath size={20} />, label: "ห้องน้ำ", value: "2 ห้อง" },
    { icon: <Car size={20} />, label: "ที่จอดรถ", value: "2 คัน" },
    { icon: <MapPin size={20} />, label: "พื้นที่", value: "56 ตร.ว." },
  ];

  return (
    <div className="h-screen w-full bg-gray-50">
      <div className="h-full w-full mx-auto p-4 overflow-y-auto">
        {/* Search Section */}
        <div className="sticky top-0 z-50 bg-white p-4 rounded-lg shadow-md border border-gray-200 mb-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <input
                type="text"
                value={assetId}
                onChange={(e) => setAssetId(e.target.value)}
                placeholder="Enter Asset ID"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              onClick={handleSearch}
              disabled={loading}
              className="flex items-center justify-center gap-2 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-300"
            >
              {loading ? (
                "Searching..."
              ) : (
                <>
                  <Search size={20} />
                  Search
                </>
              )}
            </button>
          </div>
          {error && (
            <div className="mt-2 p-2 bg-red-50 text-red-600 rounded-lg">
              {error}
            </div>
          )}
        </div>

        {data !== null ? (
          <div className="space-y-4">
            {/* Image Gallery Section */}
            {data.estate_data.images_url.AssetImagesURL.length > 0 && (
              <div className="relative w-full h-[50vh] md:h-[60vh] lg:h-[70vh] overflow-hidden rounded-xl bg-gray-100">
                <div className="relative h-full w-full">
                  {data.estate_data.images_url.AssetImagesURL.map(
                    (url, index) => (
                      <div
                        key={index}
                        className={`absolute inset-0 transition-opacity duration-300 ease-in-out ${
                          index === currentImageIndex
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                      >
                        <img
                          src={url}
                          alt={`Property view ${index + 1}`}
                          className="h-full w-full object-cover"
                        />
                      </div>
                    )
                  )}
                </div>

                {data.estate_data.images_url.AssetImagesURL.length > 1 && (
                  <>
                    <div className="absolute inset-0 flex items-center justify-between p-4">
                      <button
                        onClick={prevImage}
                        className="rounded-full bg-black/50 p-2 text-white backdrop-blur-sm transition-all hover:bg-black/70"
                      >
                        <ChevronLeft size={24} />
                      </button>
                      <button
                        onClick={nextImage}
                        className="rounded-full bg-black/50 p-2 text-white backdrop-blur-sm transition-all hover:bg-black/70"
                      >
                        <ChevronRight size={24} />
                      </button>
                    </div>

                    <div className="absolute bottom-4 left-0 right-0">
                      <div className="flex justify-center gap-2">
                        {data.estate_data.images_url.AssetImagesURL.map(
                          (_, index) => (
                            <button
                              key={index}
                              onClick={() => setCurrentImageIndex(index)}
                              className={`h-2 w-2 rounded-full transition-all ${
                                index === currentImageIndex
                                  ? "bg-white scale-125"
                                  : "bg-white/50 hover:bg-white/75"
                              }`}
                            />
                          )
                        )}
                      </div>
                    </div>

                    <div className="absolute bottom-4 right-4 rounded-full bg-black/50 px-3 py-1 text-sm text-white backdrop-blur-sm">
                      {currentImageIndex + 1} /{" "}
                      {data.estate_data.images_url.AssetImagesURL.length}
                    </div>
                  </>
                )}
              </div>
            )}

            {/* Property Title and Location */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
              <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900">
                {data.estate_data.title}
              </h1>
              <p className="text-gray-600 flex items-center gap-2 mt-2">
                <MapPin size={16} />
                {data.estate_data.description.split("\n")[2].replace("📍 ", "")}
              </p>
            </div>

            {/* Main Content Grid */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
              {/* Property Details Section */}
              <div className="xl:col-span-2 bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="border-b border-gray-200 p-4">
                  <h2 className="text-lg font-semibold flex items-center gap-2">
                    <Home className="h-5 w-5" />
                    รายละเอียดทรัพย์
                  </h2>
                </div>
                <div className="p-4 lg:p-6">
                  <div className="mb-6">
                    <p className="text-2xl lg:text-3xl font-bold text-green-600">
                      {formatPrice(data.estate_data.sell_price)}
                    </p>
                  </div>

                  {/* Highlights Grid */}
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 mb-6">
                    {highlights.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-center p-3 lg:p-4 bg-gray-50 rounded-lg"
                      >
                        <div className="text-gray-600 mb-2">{item.icon}</div>
                        <p className="text-sm text-gray-500">{item.label}</p>
                        <p className="font-semibold">{item.value}</p>
                      </div>
                    ))}
                  </div>

                  {/* Commission Details */}
                  <div className="mt-6">
                    <h3 className="font-semibold mb-4">
                      Commission Distribution
                    </h3>
                    <div className="space-y-3 max-h-[60vh] overflow-y-auto">
                      {data.listMember.map((member, index) => (
                        <div key={index} className="p-3 bg-gray-50 rounded-lg">
                          <div className="flex justify-between items-center">
                            <p className="font-semibold">{member.reason}</p>
                            <p className="font-bold text-green-600">
                              {formatPrice(member.amount)}
                            </p>
                          </div>
                          <p className="text-sm text-gray-500">
                            ID: {member.uid}
                          </p>
                          <p className="text-sm text-gray-500">
                            Date: {new Date(member.date).toLocaleDateString()}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Commission Structure Section */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="border-b border-gray-200 p-4">
                  <h2 className="text-lg font-semibold flex items-center gap-2">
                    <Users className="h-5 w-5" />
                    โครงสร้างค่าคอมมิชชั่น
                  </h2>
                </div>
                <div className="p-4 lg:p-6">
                  <div className="space-y-4">
                    <div className="p-3 bg-green-50 rounded-lg">
                      <p className="font-semibold">ชวนขาย/ซื้อสำเร็จ</p>
                      <p className="text-xl font-bold text-green-600">
                        {formatPrice(7050)}
                      </p>
                    </div>
                    <div className="p-3 bg-blue-50 rounded-lg">
                      <p className="font-semibold">Upline สายชวน</p>
                      <p className="text-xl font-bold text-blue-600">
                        {formatPrice(2350)}
                      </p>
                    </div>
                    <div className="p-3 bg-purple-50 rounded-lg">
                      <p className="font-semibold">Upline สายแชร์</p>
                      <p className="text-xl font-bold text-purple-600">
                        {formatPrice(1566.67)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-screen flex  ">
            {listRealEstateSoldOut.map((data, idx) => {
              return (
                <div className="container mx-auto p-4">
                  {listRealEstateSoldOut.map((property, idx) => (
                    <div
                      key={property.id}
                      className="bg-white rounded-lg shadow-lg overflow-hidden mb-6"
                    >
                      {/* Header Section */}
                      <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-4">
                        <div className="flex items-center space-x-2">
                          <HomeIcon className="w-6 h-6 text-white" />
                          <h2 className="text-xl font-bold text-white">
                            {property.type.toUpperCase()} {data.assetid_soldout}
                          </h2>
                        </div>
                      </div>

                      {/* Main Content */}
                      <div className="p-6">
                        {/* Title */}
                        <h3 className="text-xl font-semibold mb-4">
                          {property.title}
                        </h3>

                        {/* Price */}
                        <div className="bg-blue-50 p-4 rounded-lg mb-4">
                          <p className="text-2xl font-bold text-blue-800">
                            {formatPrice(property.sell_price)}
                          </p>
                        </div>

                        {/* Location */}
                        <div className="flex items-start space-x-2 mb-4">
                          <MapPin className="w-5 h-5 text-gray-600 mt-1" />
                          <p className="text-gray-600">
                            <p className="text-gray-600">
                              <h1>
                                Lat: {data.location.coordinates.lat}, Lng:{" "}
                                {data.location.coordinates.lng}
                              </h1>
                            </p>
                          </p>
                        </div>

                        {/* Contact Section */}
                        <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                          <h4 className="font-semibold mb-3">ติดต่อ</h4>
                          <div className="flex items-center space-x-2">
                            <Phone className="w-5 h-5 text-blue-600" />
                            <a
                              href={`tel:${property.contact.PhoneNumber}`}
                              className="text-blue-600 hover:text-blue-800"
                            >
                              {property.contact.PhoneNumber}
                            </a>
                          </div>
                          <div className="mt-2">
                            <span className="text-gray-600">
                              Line: {property.contact.Line}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckBackRealEstate;
