import axios from "axios";
import React, { useState, useEffect } from "react";
import { CardBody, Typography, Button } from "@material-tailwind/react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";

export function Reports(): JSX.Element {
  const [dataReports, setDataReports] = useState<ReportDocument[]>([]);
  const [dataShowDetailReportAsset, setDataShowDetailReportAsset] =
    useState<DataShowDetailReportAsset>({
      ID_Asset: "",
      reason: [],
      status: "",
      u_name: "",
      report_uanme: "",

    });
  const [
    moreDataFromServerReportAssetDetail,
    setMoreDataFromServerReportAssetDetail,
  ] = useState<MoreDataReportAssetDetail>({
    description: "",
    images_url: { AssetImagesURL: [], BackDeedsURL: [], FrontDeedsURL: [] },
    status: "",
    reporter: { uname: "" },
    reported_user : { uname : "" , phone_number : ""}
  });
  const [DataSentToReporterUser, setDataSentToReporterUser] =
    useState<notifyUserReport>({
      title: "",
      description: "",
      expire_date: "",
      target_uname: "",
    });
  const [open, setOpen] = React.useState(false);
  const [dataUpdateStatusAsset, setDataUpdateStatusAsset] =
    useState<updateStatusToServer>({ asset_id: "", ban_status: false });
  const [expanded, setExpanded] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  const handleOpenDialog = () => {
    setOpen(!open);
    setExpanded(!expanded);
  };
  interface ReportDocument {
    asset_id: string;
    date: string;
    googlemap_url: string;
    reason: string[];
    reporter: {
      reporter_profile_image: string;
      reporter_uname: string;
    };
    status: string;
    user: {
      profile_image: string;
      uname: string;
    };
  }

  interface DataShowDetailReportAsset {
    ID_Asset: string;
    reason: string[];
    status: string;
    u_name: string;
    report_uanme: string;
  }

  interface MoreDataReportAssetDetail {
    description: string;
    images_url: {
      AssetImagesURL: string[];
      BackDeedsURL: string[];
      FrontDeedsURL: string[];
    };
    status: string;
    reporter: {
      uname: string;
    };
    reported_user: {
      uname : string;
      phone_number : string,
    }
  }

  interface MenuItems {
    name: string;
    src: string;
    url_keyword: string;
    notify: number;
  }

  interface updateStatusToServer {
    asset_id: string;
    ban_status: boolean;
  }

  interface notifyUserReport {
    title: string;
    description: string;
    expire_date: string;
    target_uname: string;
  }

  const ListMenu: MenuItems[] = [
    {
      name: "แสดงเส้นการเดินทางไปยังทรัพย์",
      src: "https://cdn-icons-png.flaticon.com/128/2335/2335353.png",
      url_keyword: "maps",
      notify: 10,
    },
    {
      name: "ตรวจสอบโพสต์ปัญหา",
      src: "https://cdn-icons-png.flaticon.com/512/8103/8103709.png",
      url_keyword: "check",
      notify: 10,
    },
    {
      name: `สถานะบนเซิฟเวอร์ ${moreDataFromServerReportAssetDetail!.status}`,
      src: `${
        moreDataFromServerReportAssetDetail!.status === "pending"
          ? "https://cdn-icons-png.flaticon.com/512/4785/4785944.png"
          : "https://cdn-icons-png.flaticon.com/512/4785/4785997.png"
      }`,
      url_keyword: "",
      notify: 10,
    },
    // {
    //     name: `status : ${dataUpdateStatusAsset!.ban_status}`,
    //     src: `${moreDataFromServerReportAssetDetail!.status == "pending" ? "https://cdn-icons-png.flaticon.com/512/4785/4785944.png" : "https://cdn-icons-png.flaticon.com/512/4785/4785997.png"}`,
    //     link: "/assets",
    //     notify: 10,
    // },
  ];

  // Inital get requset to server for display data show admin
  useEffect(() => {
    getReportsDocument();
  }, []);

  //  Get Detail After Click in card
  function getDetailReportAsset(ID_Asset: string) {
    axios
      .post(`${process.env.REACT_APP_ADMIN_API}/reports/asset/detail`, {
        asset_id: ID_Asset,
      })
      .then((res) => {
        console.log(res.data);
        setMoreDataFromServerReportAssetDetail(res.data);
        setDataUpdateStatusAsset({
          ...dataUpdateStatusAsset,
          asset_id: ID_Asset,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleUpdateStatus(status: boolean) {
    setDataUpdateStatusAsset({
      ...dataUpdateStatusAsset,
      ban_status: status,
    });
  }

  // Function Inital for get data Reports
  async function getReportsDocument() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ADMIN_API}/reports/asset`
      );
      console.log(res.data);
      const data = res.data;

      // Sort the data based on the 'status' property
      const sortedData = data.sort((a: any, b: any) => {
        if (a.status === "pending" && b.status !== "pending") {
          return -1;
        } else if (a.status !== "pending" && b.status === "pending") {
          return 1;
        } else {
          return 0;
        }
      });

      console.log(data);
      setDataReports(sortedData);
    } catch (err) {
      console.error(err);
    }
  }

  function handleShowDetailReportAsset(
    ID_Asset: string,
    reason: string[],
    status: string,
    u_name: string,
    report_uanme: string,
    googlemap_url: string
  ): void {
    const dataStruc = {
      ID_Asset,
      reason, // Join the array of reasons into a string
      status,
      u_name,
      report_uanme,
    };

    sessionStorage.setItem("temp_IDasset_report", ID_Asset);
    sessionStorage.setItem("temp_map_navigate_report", googlemap_url);
    // sessionStorage.setItem("temp_lng_report" , lngStr)
    setDataShowDetailReportAsset(dataStruc);
    getDetailReportAsset(ID_Asset);

    handleOpenDialog();
  }

  // Funciton Update Status Ban or Approved Asset

  function UpdateStatusAssets(): Promise<boolean> {
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.REACT_APP_ADMIN_API}/action/asset/ban`,
          {
            asset_id: dataUpdateStatusAsset.asset_id,
            ban_status: dataUpdateStatusAsset.ban_status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setExpanded(!expanded);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(`ระบบอัพเดทสถานะการแบนเกิดข้อผิดพลาด`, `${err}`, "error");
          resolve(false);
        });
    });
  }

  // Function sendNotifyToReportedUser only

  function sendNotifyReportedUser(): Promise<boolean> {
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.REACT_APP_ADMIN_API}/inbox/send`,
          {
            title: DataSentToReporterUser.title,
            description: DataSentToReporterUser.description,
            target_uname: moreDataFromServerReportAssetDetail.reported_user.uname,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          resolve(true);
        })
        .catch((err) => {
          Swal.fire(`ระบบแจ้งเตือนผู้ใช้งานเกิดข้อผิดพลาด`, `${err}`, "error");
          resolve(false);
        });
    });
  }
  //  Main Functoin For Update All Data
  async function handleUpdateAssets() {
    try {
      const updateStatus = await UpdateStatusAssets();
      const sendNotify = await sendNotifyReportedUser();

      if (updateStatus && sendNotify) {
        Swal.fire("อัพเดทข้อมูลเรียบร้อย", "", "success");
        getReportsDocument();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Function HandleURLs for navigate to another page
  function handleURLs(url_keyword: string) {
    const temp_id_asset = sessionStorage.getItem("temp_IDasset_report");
    const temp_navigate_map_url = sessionStorage.getItem(
      "temp_map_navigate_report"
    );
    console.log(url_keyword);

    switch (url_keyword) {
      case "maps":
        if (temp_navigate_map_url) window.open(temp_navigate_map_url, "_blank");
        break;
      case "check":
        window.open(`https://postperty.com/product/${temp_id_asset}`, "_blank");
        sessionStorage.removeItem("temp_IDasset_report");
        break;

      default:
        console.log("run default");
    }
  }

  // Function to handle changes in input or textarea
  function handleNotifyUser(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    // Update state based on the input key and value
    const expire_key = "expire_date";
    setDataSentToReporterUser((prevData) => ({
      ...prevData,
      [key]:
        key === expire_key
          ? null
          : (e.target as HTMLInputElement | HTMLTextAreaElement).value,
    }));
  }

  return (
    <main className="relative w-full h-full  mt-16">
      <div
        className={`absolute  top-6 left-1/2 transform -translate-x-1/2 transition-all ease-in-out duration-500 w-full font-main p-2.5   bg-white   rounded-xl md:w-8/12 ${
          expanded
            ? "h-[630px] w-[980px] border z-40"
            : "top-[10px]  left-[-300px] h-[0px] w-[0px] "
        }`}
      >
        <img
          className="w-8 h-8 transform transition-transform ease-in-out duration-500 z-50 cursor-pointer mb-2 hover:bg-gray-100 rounded-xl"
          src={
            expanded
              ? "https://cdn-icons-png.flaticon.com/128/2989/2989988.png"
              : "https://cdn-icons-png.flaticon.com/128/2989/2989988.png"
          }
          alt="arrow"
          style={expanded ? { transform: "rotate(180deg)" } : {}}
          onClick={() => handleOpenDialog()}
        />

        <div
          className={`absolute top-2 right-2 w-36 h-10  flex justify-center items-center hover:bg-gray-100 hover:rounded-full cursor-pointer ${
            expanded ? "w-36" : "hidden"
          }`}
          onClick={() => handleUpdateAssets()}
        >
          <h1>บันทึก</h1>
        </div>

        {/* <h1 className={expanded ? 'flex'}>test</h1> */}

        <div
          className={`"bg-white transition-all ease-in-out duration-2000 " ${
            expanded ? "opacity-100  rounded-xl p-2.5" : "opacity-0"
          }`}
        >
          <h1 className="font-main">
            ไอดีทรัพย์ : {dataShowDetailReportAsset!.ID_Asset}
          </h1>
          <p className="font-main font-medium text-red-500 w-full">{`เหตุผลการรายงาน : ${
            dataShowDetailReportAsset!.reason
          }`}</p>

          <div
            className={`relative overflow-y-auto  grid grid-cols-12  rounded-xl p-2.5 h-[500px] border`}
          >
            <div className="col-span-12 flex flex-col   md:flex-row">
              {ListMenu.map((data, index: number) => (
                <motion.div
                  className="relative mt-6 w-full cursor-pointer hover:bg-gray-100 hover:rounded-xl md:w-80"
                  key={index}
                  onClick={() => handleURLs(data.url_keyword)}
                  whileHover={{ scale: 1.03 }}
                >
                  <div className="relative h-56   bg-black mt-6 p-12">
                    <img
                      src={data?.src}
                      alt={`${data.src}`}
                      className="w-full h-full object-contain p-2.5 "
                      loading="lazy"
                    />
                  </div>
                  <CardBody>
                    <p className="mb-2 font-main font-">{data.name}</p>
                  </CardBody>
                </motion.div>
              ))}
            </div>

            <div className="col-span-12 flex flex-col  h-[300px] p-2.5">
              <span className=" mx-auto w-full md:w-10/12">{`ผู้รับ : ${moreDataFromServerReportAssetDetail.reported_user.uname} (ตอบกลับคนถูกรายงาน)`}</span>
              <span className=" mx-auto w-full md:w-10/12">{`${moreDataFromServerReportAssetDetail.reported_user.phone_number} (เบอร์คนถูกรายงาน)`}</span>
              <input
                type="text"
                placeholder="เรื่อง"
                className="border bg-gray-100 h-12 w-full rounded-xl p-2.5 outline-0 mx-auto md:w-10/12"
                onChange={(e) => handleNotifyUser(e, "title")}
              />
              <textarea
                className="border bg-gray-100 w-full rounded-xl p-2.5 h-[200px] text-start mt-2.5 outline-0 mx-auto md:w-10/12"
                onChange={(e) => handleNotifyUser(e, "description")} // Change the key to 'description' for the textarea
              />
            </div>
            {/* <img src={moreDataFromServerReportAssetDetail?.images_url?.AssetImagesURL[0]} alt={ID_Asset} className="w-full object-cover col-span-6" /> */}
            <div className="  w-full font-main p-2.5 col-span-12">
              <div className="grid grid-cols-12 gap-2.5 mt-12">
                <div
                  className={`z-50 col-span-6 h-12 rounded-xl font-main font-medium flex justify-center items-center cursor-pointer ${
                    dataUpdateStatusAsset!.ban_status
                      ? " bg-red-500 text-white"
                      : "bg-gray-100 hover:bg-red-500 hover:text-white"
                  }`}
                  onClick={() => handleUpdateStatus(true)}
                >
                  แบน (Ban)
                </div>
                <div
                  className={`z-50 col-span-6 h-12 rounded-xl font-main font-medium flex justify-center items-center cursor-pointer ${
                    dataUpdateStatusAsset!.ban_status
                      ? "bg-gray-100 hover:bg-green-300 hover:text-white"
                      : " bg-green-300 text-white"
                  }`}
                  onClick={() => handleUpdateStatus(false)}
                >
                  อนุมัติ (Approve)
                </div>
              </div>
            </div>
          </div>
          {/* <div className="space-x-2">
                        <Button variant="text" color="blue-gray" onClick={handleOpenDialog}>
                            Cancel
                        </Button>
                        <Button variant="gradient" className="bg-black" onClick={handleOpenDialog}>
                            Confirm
                        </Button>
                    </div> */}
        </div>
      </div>
      {/* </div> */}
      <div
        className={`grid grid-cols-12 gap-x-2.5 w-full h-[900px] border  overflow-y-auto p-7 ${
          expanded ? "blur-sm" : ""
        }`}
      >
        {dataReports.map((asset: ReportDocument, index: number) => {
          const ID_Asset = asset?.asset_id;
          // const imageUrl = asset?.images_url?.AssetImagesURL?.[0] ?? '';
          const reasonReport = asset?.reason?.[0];
          const reason = asset?.reason;
          const status = asset?.status;
          const u_name = asset?.user?.uname;
          const report_uanme = asset?.reporter?.reporter_uname;
          const googlemap_url = asset?.googlemap_url;

          // const profile = asset?.user?.profile_image;
          const date = asset?.date;
          return (
            <motion.div
              className="relative mt-2.5 w-90 col-span-12  cursor-pointer border rounded-xl  hover:bg-gray-100 hover:rounded-xl md:col-span-3"
              key={index}
              whileHover={{ scale: 1.05 }} // Scale on hover
              // onClick={() => ModalDetail(ID_Asset, [reasonReport], status, u_name, report_uanme)}
              onClick={() =>
                handleShowDetailReportAsset(
                  ID_Asset,
                  reason,
                  status,
                  u_name,
                  report_uanme,
                  googlemap_url
                )
              }
            >
              <CardBody>
                <Typography
                  variant="h5"
                  className="mb-2 font-main text-red-500"
                >
                  {reasonReport.slice(0, 30)}
                </Typography>
                <p className="font-main">{`ID ทรัพย์ : ${ID_Asset}`}</p>
                <p className="font-main">{`ผู้รายงาน : ${report_uanme}`}</p>

                <p className="font-main">{`ผู้ถูกรายงาน : ${u_name}`}</p>

                <p className="font-main">{`เวลา : ${date.slice(0, 19)}`}</p>
              </CardBody>
              <div className="pt-0 absolute right-0 top-[-2px]">
                <Button
                  className={
                    status === "pending"
                      ? "bg-orange-500 font-main"
                      : status === "true"
                      ? "bg-green-500 font-main"
                      : "bg-red-500 font-main"
                  }
                >
                  {status === "true"
                    ? "ไม่มีผิดเงื่อนไข"
                    : status === "false"
                    ? "แบนเรียบร้อย"
                    : "รอดำเนินการ"}
                </Button>
              </div>
            </motion.div>
          );
        })}
      </div>
    </main>
  );
}
