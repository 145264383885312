import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { formatDate } from "../../utility/Function";
import Swal from "sweetalert2";
function NoteOfLead(): JSX.Element {
  interface GetNoteStruc {
    note: string;
    time: string;
    note_id: string;
  }
  interface FormData {
    lead_id: string;
    note_text: string;
  }

  const id_asset_for_note = sessionStorage.getItem("idasset_note" )


  const initialFormData: FormData = {
    lead_id: id_asset_for_note ? id_asset_for_note : "", // test lead note
    note_text: "",
  };

  const [notesData, setNotesData] = useState<GetNoteStruc[]>([]);
  const [dataUpload, setDataUpload] = useState<FormData>(initialFormData);
  useEffect(() => {
    getNote();
  }, []);
  function getNote(): void {
    try {
      axios
        .post(`${process.env.REACT_APP_ADMIN_API}/event/note/getnote`, {
          lead_id: id_asset_for_note ? id_asset_for_note : "",
        })
        .then((res) => {
          const note_data = res.data?.note_data.reverse();
          setNotesData(note_data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function savedNote(): void {
    try {
        if(dataUpload?.note_text.length > 0){
            axios
            .post(`${process.env.REACT_APP_ADMIN_API}/event/note/savenote`, {
              lead_id: dataUpload?.lead_id,
              note_text: dataUpload?.note_text,
            })
            .then((res) => {
              const success = res.data?.Success;
              if (success) {
                Swal.fire("", "บันทึกโน้ตสำเร็จ", "success");
                getNote();
              }
            })
            .catch((err) => {
              Swal.fire(
                "ไม่สามารถบันทึกโน๊ตได้",
                `เพราะ :${err.response.data.Error}`,
                "error"
              );
            });
        }else{
            Swal.fire(
                "กรุณาระบุข้อความของโน้ต",
                "",
                "error"
              );
        }
     
    } catch (error) {
      console.error(error);
    }
  }

  function inputValue(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ): void {
    const value = e.target.value;
    setDataUpload((prevData) => ({ ...prevData, [key]: value }));
  }
  return (
    <main className="w-full h-[1000px]  flex justify-center items-center">
      <div className=" border rounded-xl w-[92%] h-[900px] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 ">
          <div className=" relative w-full h-full p-2.5">
            <span className="absolute top-2.5 left-1/2 transform -translate-x-1/2 text-xl">
              บันทึกโน้ตใหม่ (2048)
            </span>

            <div onClick={()=>savedNote()} className="absolute top-2.5 right-2 w-48 h-8 flex justify-center items-center border border-blue-500 text-black cursor-pointer rounded-full">
              บันทึก
            </div>

            <textarea
              onChange={(e) => inputValue(e, "note_text")}
              placeholder="ระบุโน้ตตรงนี้!"
              className="p-2.5 flex text-start  rounded-xl  mt-10 w-full h-[50%] outline-none"
            />
            <div className="h-[45%] flex text-5xl text-green-500 justify-center items-center border ">
              ระบบ Note พร้อมใช้งาน
            </div>
          </div>

          <div className="p-2.5 overflow-y-auto border  relative flex flex-col justify-center items-start w-full h-[900px] rounded-2xl text-white bg-black">
            <span className="absolute top-6 left-1/2 transform -translate-x-1/2 text-xl font-medium w-full text-center">
             {` ประวัติการจดบันทึก ${id_asset_for_note}`} 
            </span>

            <div className="flex flex-col h-[900px] p-2.5 overflow-y-auto mt-12">
              {notesData.map((note) => {
                return (
                  <main
                    key={note?.note_id}
                    className="relative w-full  min-w-[500px]  border border-blue-500  rounded-xl p-2.5 mt-2.5  "
                  >
                    <p className="mt-8">{`${note?.note}`}</p>
                    <span className="absolute top-2.5 right-2">{`วันที่ ${note?.time}`}</span>
                  </main>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NoteOfLead;
