import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import Swal from 'sweetalert2';
interface Location {
  lat: number;
  lng: number;
}

export default function MapForPosts() {
  const [clickedLocation, setClickedLocation] = useState<Location | null>(null);
  const [isCorrectLocation , setIsCorrectLocation] = useState(false)
  const [centerOfMap , setCenterOfmap] = useState({ lat: 13.734822848115485, lng: 100.51521019920843 })
  const handleMapClick = (event : any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const coordinate = {lat , lng}
    sessionStorage.setItem("LatForPost" , lat);
    sessionStorage.setItem("LngForPost" , lng);
    setClickedLocation(coordinate)
  };


  const handleLatLng = (e : any)=>{
    const dataLatLng = e.target.value.replaceAll(" ","");
    const LatLng = dataLatLng.split(",")

    if(!isNaN(LatLng[0]) && !isNaN(LatLng[1]))  {
      const Lat =   parseFloat(LatLng[0])
      const Lng =   parseFloat(LatLng[1])



      setClickedLocation({lat : Lat, lng: Lng});
      setCenterOfmap({lat : Lat , lng : Lng})
      sessionStorage.setItem("LatForPost" , Lat.toString());
      sessionStorage.setItem("LngForPost" , Lng.toString());

    }
    else console.log("ตำแหน่งไม่ถูกต้อง")
    
  }





  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });

  const mapContainerStyle = {
    width: '100%',
    height: '800px',
    borderRadius: '17px',
    marginTop : '20px',
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <main className="w-full h-screen mb-10">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{
          gestureHandling: 'greedy',
          disableDefaultUI: true,
          mapTypeId: 'hybrid',
          clickableIcons: false,
        }}
        center={centerOfMap}
        zoom={12}
        onClick={handleMapClick}
      >
        {clickedLocation && (
          <Marker position={{ lat: clickedLocation.lat, lng: clickedLocation.lng }} />
          // <Marker position={{ lat: 13.72733535437077, lng: 100.51270129651897 }} />

          
        )}
      </GoogleMap>


      <input defaultValue={clickedLocation ? `${clickedLocation.lat}, ${clickedLocation.lng}` : ''} type='text' placeholder='Lat,Lng'  className='text-center w-full h-12 rounded-full bg-gray-100 mt-2.5' onChange={(e)=>handleLatLng(e)}/>
    </main>
  );
}
