import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from './Modal_Document'; // Import the Modal component
import { motion } from 'framer-motion';

interface DocumentData {
    id: string;
    asset_id: string;
    assignee: string;
    documents_url: {
        allFiles: string[];
        allImages: string[];
    };
    type_work: string;
    created_date: string;
    status: string;
    first_name : string;
    last_name : string;
}

export default function OnsiteAllDocument() {
    const [documents, setDocuments] = useState<DocumentData[]>([]);
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentData[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]); // Adjusted type to any[]
    const [selectedAssetId, setSelectedAssetId] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [filterName, setFilterName] = useState<string>('');
    const [filterDate, setFilterDate] = useState<string>('');
    const [filterSize, setFilterSize] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<DocumentData[]>(`${process.env.REACT_APP_ADMIN_API}/fetchData/onsite/getDocuments`);
                setDocuments(response.data);
                setFilteredDocuments(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            let result = documents;

            // Filter by name
            if (filterName) {
                result = result.filter(doc =>
                    doc.asset_id.toLowerCase().includes(filterName.toLowerCase())
                );
            }

            // Filter by date
            if (filterDate) {
                // Convert filterDate to Date object
                const [day, month, year] = filterDate.split('/').map(Number);
                const filterDateObj = new Date(year, month - 1, day);

                result = result.filter(doc => {
                    const docDate = new Date(doc.created_date);
                    return (
                        docDate.getDate() === filterDateObj.getDate() &&
                        docDate.getMonth() === filterDateObj.getMonth() &&
                        docDate.getFullYear() === filterDateObj.getFullYear()
                    );
                });
            }

            setFilteredDocuments(result);
        };

        applyFilters();
    }, [filterName, filterDate, filterSize, documents]);

    const openModal = (files: string[], assetId: string , type_work : any , status : string | boolean , date_fileuploaded : any) => {
        // Assuming you need to attach additional details to each file
        const fileDetails = files.map(file => ({
            url: file,
            type_work: type_work, // Replace with actual data if available
            created_date: date_fileuploaded, // Format current date
            status: status, // Replace with actual data if available
            asset_id: assetId
        }));
        
        setSelectedFiles(fileDetails);
        setSelectedAssetId(assetId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFiles([]);
        setSelectedAssetId('');
    };

    return (
<main className="w-full  max-h-screen h-screen">
            <div className="p-4 w-full max-h-screen h-screen  overflow-y-auto border border-blue-500">
                <div className="mb-4 p-2.5">
                    <input
                        type="text"
                        placeholder="Filter by name"
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                        className="outline-0 border p-2 mr-2 rounded-xl"
                    />
                    <input
                        type="text"
                        placeholder="Filter by date (dd/mm/yyyy)"
                        value={filterDate}
                        onChange={(e) => setFilterDate(e.target.value)}
                        className="w-80 outline-0 border p-2 mr-2 rounded-xl"
                    />
                    {/* Size filter is more complex and may require additional logic */}
                </div>
                <div className="w-full h-full flex flex-wrap justify-start p-2.5 overflow-hidden">
                    {filteredDocuments.map((document) => (
                        <motion.div
                            key={document.id}
                            className="flex flex-col justify-center w-full sm:w-36 md:w-48 lg:w-56 h-36 rounded-xl border p-1 m-2 cursor-pointer"
                            onClick={() => openModal(document.documents_url.allFiles, document.asset_id ,document.type_work,document.status , document.created_date)}
                            whileHover={{ scale: 1.15 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="w-full h-full flex flex-col justify-center ml-2">
                                <img className="w-8 h-8" src="https://cdn-icons-png.flaticon.com/512/3735/3735057.png" alt="folder-icon" />
                                <h1 className="text-sm">{document.asset_id}</h1>
                                <p className="text-[12px] mb-1">
                                    {document.created_date}
                                </p>
                                <div className='flex w-full h-full'>
                                <img className="w-4 h-4" src="https://cdn-icons-png.flaticon.com/512/456/456212.png" alt="onsite-profile" />
                                <p className='text-sm ml-2.5'>{document.first_name + document.last_name}</p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                files={selectedFiles}
                assetId={selectedAssetId}
            />
        </main>
    );
}
