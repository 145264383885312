import axios from "axios";
import React, { useEffect, useId, useState } from "react";
import Swal from "sweetalert2";

const token = localStorage.getItem(`token`);

interface VerifyUserData {
  uid: string;
  fullname: string;
  bookbank_id: string;
  bank: string;
  bankbook_image: string[];
  idcard_image: string[];
  date: string;
  status: string;
}

interface StatData {
  pending: string;
  processing: string;
  approved: string;
  rejected: string;
}

const VerifyUser = () => {
  const [verifyUserData, setVerifyUserData] = useState<VerifyUserData[]>([]);
  const [statData, setStatData] = useState<StatData>({
    pending: "0",
    processing: "0",
    approved: "0",
    rejected: "0",
  });
  const [showModalRejected, setShowModalRejected] = useState(false);
  const [targetUserUIDForRejected, setTargetUserUIDForRejected] = useState("");
  const [reasonRejected, setReasonRejected] = useState("");
  const [previewImageCentral , setPreviewImageCentral] = useState(false);
  const [imageURLPreview , setImageURLPreview] = useState('');
  const statDisplay = [
    {
      title: "กำลังรอ (pending)",
      count: statData?.pending,
    },
    {
      title: "กำลังดำเนินการ (processing)",
      count: statData?.processing,
    },
    {
      title: "อนุมัติ (approved)",
      count: statData?.approved,
    },
    {
      title: "ปฏิเสธ (rejected)",
      count: statData?.rejected,
    },
  ];
  useEffect(() => {
    getVerifyUserData();
  }, []);

  function getVerifyUserData(): void {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/verify/userdata`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        const verifyData = res.data;
        setVerifyUserData(verifyData);
        setStatData({
          pending: verifyData
            .filter((data: any) => data.status === "pending")
            .length.toString(),
          processing: verifyData
            .filter((data: any) => data.status === "processing")
            .length.toString(),
          approved: verifyData
            .filter((data: any) => data.status === "approved")
            .length.toString(),
          rejected: verifyData
            .filter((data: any) => data.status === "rejected")
            .length.toString(),
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  function updateStatusUser(userUID : string, newStatus : string,  reason?: string ) : void{
        axios.post(`${process.env.REACT_APP_ADMIN_API}/verify/update-user-verification`,{
            target_uid : userUID,
            status : newStatus,
            reason : reason,
        },{
            headers: {
                Authorization : `Bearer ${token}`
            }
        }).then((res : any)=>{
            console.log(res.data)
            Swal.fire(`` , `${res.data.Success}` , 'success')
        }).catch((err : any)=>{
            Swal.fire(`` , `${err.response.data.Error}` , 'error');
        })

        setShowModalRejected(false);
  }

  function handleChangeStatus(
    index: number,
    newStatus: string,
    verifyUserUID: string
  ): void {
    console.log('new status' , newStatus)
    if (newStatus === "rejected") {
      setShowModalRejected(true);
      setTargetUserUIDForRejected(verifyUserUID);
    } else if (newStatus === "approved" || newStatus === "processing"){
       updateStatusUser(verifyUserUID , newStatus)
    }


    const updateVerifyUserData = [...verifyUserData];
    verifyUserData[index].status = newStatus;
    setVerifyUserData(updateVerifyUserData)
  }

  function handleReasonRejected(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const value = e.target.value;
    setReasonRejected(value);
  }

  function handleRejectVerifyUser(): void {
      updateStatusUser(targetUserUIDForRejected , "rejected" , reasonRejected)
  }

  function handleShowPreviewImage(imageURL : string){
    setPreviewImageCentral(true);
    setImageURLPreview(imageURL);
  }

 

  return (
    <main className="relative w-full h-full " >
      {showModalRejected ? (
        <div className="flex flex-col absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[600px] w-8/12 z-50 bg-black p-4">
            <div className="z-50 text-red-500 text-5xl absolute left-6  p-2.5 rounded-full top-2.5 cursor-pointer" onClick={()=>setShowModalRejected(false)}>X</div>
          <div className="relative flex justify-center items-center w-full h-full  mx-auto">
            <input
              type="text"
              placeholder="ระบุเหตุผล"
              className="w-8/12 h-80 p-2.5  mx-auto rounded-xl text-center"
              onChange={(e) => handleReasonRejected(e)}
            />
            <div
              onClick={() => {
                handleRejectVerifyUser();
              }}
              className="absolute right-6 bottom-6 bg-red-500 text-white flex justify-center items-center p-2.5 rounded-full cursor-pointer"
            >
              ยืนยันการไม่อนุมัติ
            </div>
          </div>
        </div>
      ) : null}


      {previewImageCentral ? <img onClick={()=>setPreviewImageCentral(false)} src={imageURLPreview} className=" cursor-pointer absolute left-1/2 top-10 transform -translate-x-1/2  h-auto z-50"/> : null}
      <div className="w-full h-[200px]  bg-black ">
        {/* Statistics display */}
        <div className="text-white h-full grid grid-cols-4">
          {statDisplay.map((data) => {
            return (
              <div className="w-full h-full flex flex-col justify-center items-center">
                <h3>{data.title}</h3>
                <span className="text-5xl">{data.count}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative h-[710px]  sm:rounded-lg overflow-y-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
            <tr>
              {/* Table headers */}
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                UID
              </th>
              <th scope="col" className="px-6 py-3">
                Full Name
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Bank
              </th>
              <th scope="col" className="px-6 py-3">
                Bank ID
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Bankbook Image
              </th>
              <th scope="col" className="px-6 py-3">
                ID Card Image
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {verifyUserData.map(
              (
                {
                  uid,
                  fullname,
                  bookbank_id,
                  bank,
                  bankbook_image,
                  idcard_image,
                  date,
                  status,
                },
                index
              ) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 dark:border-gray-700"
                >
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    {uid}
                  </td>
                  <td className="px-6 py-4">{fullname}</td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {bank}
                  </td>
                  <td className="px-6 py-4">{bookbank_id}</td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {bankbook_image.map((image, index) => (
                      <img
                        onClick={() => handleShowPreviewImage(image)}
                        key={index}
                        src={image}
                        alt={`Bankbook Image ${index}`}
                        className="h-16 cursor-pointer"
                      />
                    ))}
                  </td>
                  <td className="px-6 py-4">
                    {idcard_image.map((image, index) => (
                      <img
                      onClick={() =>  handleShowPreviewImage(image)}
                 
                        key={index}
                        src={image}
                        alt={`ID Card Image ${index}`}
                        className="h-16 cursor-pointer"
                      />
                    ))}
                  </td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {new Date(date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4">
                    <select
                      value={status}
                      onChange={(e) =>
                        handleChangeStatus(index, e.target.value, uid)
                      }
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default VerifyUser;
