import CardOne from "./CardOne";
import CardTwo from "./CardTwo";
import CardThree from "./CardThree";
import CardFour from "./CardFour";
import CardFive from "./CardFive";

import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import ChartThree from "./ChartThree";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const Dashboard = () => {
  interface commissionData {
    commissionPercentageBy100: {
      share_holding_total_percentage: string;
      postperty_holding_total_percentage: string;
      invite_regis_holding_percentage: string;
      lead_holding_percentage: string;
      invite_holding_total_percentage: string;
      lead_upline_percentage: string;
      share_upline_percentage: string;
      invite_regis_upline_percentage: string;
    };
    totalPrice: {
      mlm_commission_total_price: string;
      postperty_holding_price: string;
      invite_regis_holding_price: string;
      lead_holding_total_price: string;
      share_holding_total_price: string;
    };
  }

  interface Upline {
    uname: string;
    uid: string;
    profile_image: string;
    date_created: string | null;
    phone_number: string;
    upline_money_per_person?: number | null;
  }

  interface MLMDataJSON {
    leadData: {
      leadUplineCount: string;
      lead_successor: Upline;
      lead_upline: Upline[];
      upline_money_per_person: number | null;
    };
    shareData: {
      shareUplineCount: string;
      share_successor: Upline;
      share_upline: Upline[];
      upline_money_per_person: number | null;
    };
    inviteData: {
      users: any[]; // Assuming users can be of any type
      inviteUplineCount: number;
      invite_successor: Upline;
      upline_money_per_person: number | null;
    };
  }


  interface BuyerDataJSON {
    uname: string;
    uid: string;
    profile_image: string;
    date_created: string;
    phone_number: string;
  }
  const initMLMData: MLMDataJSON = {
    leadData: {
      leadUplineCount: "",
      lead_successor: {
        uname: "",
        uid: "",
        profile_image: "",
        date_created: "",
        phone_number: "",
      },
      lead_upline: [
        {
          uname: "",
          uid: "",
          profile_image: "",
          upline_money_per_person: 0,
          date_created: null,
          phone_number: "",
        },
      ],
      upline_money_per_person: 0,
    },
    shareData: {
      shareUplineCount: "",
      share_successor: {
        uname: "",
        uid: "",
        profile_image: "",
        date_created: "",
        phone_number: "",
      },
      share_upline: [
        {
          uname: "",
          uid: "",
          profile_image: "",
          upline_money_per_person: 0,
          date_created: "",
          phone_number: "",
        },
      ],
      upline_money_per_person: 0,
    },
    inviteData: {
      users: [],
      inviteUplineCount: 0,
      invite_successor: {
        uname: "",
        uid: "",
        profile_image: "",
        date_created: null,
        phone_number: "",
      },
      upline_money_per_person: null,
    },
  };

  const initCommissionData = {
    commissionPercentageBy100: {
      share_holding_total_percentage: "0",
      postperty_holding_total_percentage: "0",
      invite_regis_holding_percentage: "0",
      lead_holding_percentage: "0",
      invite_holding_total_percentage: "0",
      lead_upline_percentage: "0",
      share_upline_percentage: "0",
      invite_regis_upline_percentage: "0",
    },
    totalPrice: {
      mlm_commission_total_price: "0",
      postperty_holding_price: "0",
      invite_regis_holding_price: "0",
      lead_holding_total_price: "0",
      share_holding_total_price: "0",
    },
  };


  const initBuyerData: BuyerDataJSON = {
    uname: "",
    uid: "",
    profile_image: "",
    date_created: "",
    phone_number: "",
  };

  
  const [commissionData, setCommissionData] =
    useState<commissionData>(initCommissionData);
  const [MLMData, setMLMData] = useState<MLMDataJSON>(initMLMData);
  const [buyerData , setBuyerData] = useState<BuyerDataJSON>(initBuyerData)
  const [idAssetSearch, setIdAssetSearch] = useState("");
  const [confirmAIDDisplay, setConfirmAIDDisplay] = useState("");
  const token = localStorage.getItem(`token`);

  console.log("Confirm AID", confirmAIDDisplay);
  function getCommission(id_aid: string): void {
    axios
      .get(
        `${
          process.env.REACT_APP_ADMIN_API
        }/commission/getCommissionData/${`${id_aid}`}`
      )
      .then((res) => {
        const dataCalculated = res.data.calculatedJsonData;
        const MLMData = res.data.mlmDataJson;
        const buyerData = res.data.buyerDataJson
        setCommissionData(dataCalculated);
        setMLMData(MLMData);
        setBuyerData(buyerData);
        setConfirmAIDDisplay(id_aid); // for confirm aid share money 

      })
      .catch((err) => {
        console.log(err);
        setConfirmAIDDisplay("อสังหาฯนี้ไม่มีในระบบหรือยังไม่ได้อนุมัติโพสต์");
      });
  }

  function inputValue(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const value = e.target.value;
    console.log(value);
    setIdAssetSearch(value);
    getCommission(value);
  }

  function handleShareCommission(): void {
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_API}/commission/confirmCommission`,
        {
          asset_id: confirmAIDDisplay,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire(`กระจายเงินให้สมาชิกสำเร็จ`, ``, "success");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  console.log(commissionData);
  console.log("mlm data", MLMData);
  useEffect(() => {
    getCommission(idAssetSearch);
  }, []);
  return (
    <main className="relative w-full h-[910px] overflow-y-auto   p-2.5 ">
      <div
        onClick={() => handleShareCommission()}
        className="absolute top-2.5 right-2.5 w-96 h-12 rounded-full bg-postperty flex justify-center items-center text-white cursor-pointer"
      >
        ยืนยันการกระจายรายได้
      </div>
      <input
        onChange={(e) => inputValue(e)}
        type="text"
        placeholder="ระบุ ID อสังหาฯ เช่น CR-NPT-231031-0001"
        className="h-12 border outline-0 w-full  text-center rounded-full"
      />
      <span
        className={`mt-4 text-red-500`}
      >{`ข้อมูลทรัพย์ที่แสดงคือ : ${confirmAIDDisplay}`}</span>
      <div className="border  mt-4 grid grid-cols-4 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <div className=" h-full flex flex-col justify-center items-center ">
          <div className="w-full h-[150px]  border  flex flex-col justify-center items-center">
            <h3>{`คน Lead สำเร็จ`}</h3>
            <img
              src={MLMData?.leadData?.lead_successor?.profile_image}
              className="w-16 h-16"
              alt="lead_successor"
            />
            <span>{MLMData?.leadData?.lead_successor?.uname}</span>
            <span>{`เบอร์โทรติดต่อ :${MLMData?.leadData?.lead_successor?.phone_number}`}</span>
          </div>

          <div className="h-[350px] border  w-full  flex flex-col overflow-y-auto">
            <p>{`Lead Upline (สายLead) จำนวน ${MLMData?.leadData?.leadUplineCount} คน`}</p>
            {MLMData?.leadData?.lead_upline.map((data) => {
              return (
                <div className="flex justify-center items-start ">
                  <div className="flex w-full items-center justify-around h-12 border  ">
                    <img
                      src={data?.profile_image}
                      className="w-8 h-8 rounded-full aspect-square"
                      alt={`LeadUpline-Profile ${data?.uid}`}
                    />
                    <span>{data?.uname}</span>
                    <span>{data?.upline_money_per_person} THB</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className=" h-full flex flex-col justify-center items-center ">
          <div className="w-full h-[150px]  border  flex flex-col justify-center items-center">
            <h3>{`คนแชร์สำเร็จ`}</h3>
            <img
              src={MLMData?.shareData?.share_successor?.profile_image}
              className="w-16 h-16 rounded-full"
              alt="share_successor"
            />
            <span>{MLMData?.shareData?.share_successor?.uname}</span>
            <span>{`เบอร์โทรติดต่อ :${MLMData?.shareData?.share_successor?.phone_number}`}</span>
          </div>

          <div className="h-[350px] border  w-full  flex flex-col overflow-y-auto">
            <p>{`Share Upline (สายแชร์) จำนวน ${MLMData?.shareData?.shareUplineCount} คน`}</p>
            {MLMData?.shareData?.share_upline.map((data) => {
              return (
                <div className="flex justify-center items-start ">
                  <div className="flex w-full items-center justify-around h-12 border  ">
                    <img
                      src={data?.profile_image}
                      className="w-8 h-8 rounded-full aspect-square"
                      alt={`LeadUpline-Profile ${data?.uid}`}
                    />
                    <span>{data?.uname}</span>
                    <span>{data?.upline_money_per_person} THB</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className=" h-full flex flex-col justify-center items-center ">
          <div className="w-full h-[150px]  border  flex flex-col justify-center items-center">
            <h3>{`คนชวนสำเร็จ`}</h3>
            <img
              src={MLMData?.inviteData?.invite_successor?.profile_image}
              className="w-16 h-16 rounded-full"
              alt="invite_successor"
            />
            <span>{MLMData?.inviteData?.invite_successor?.uname}</span>
            <span>{`เบอร์โทรติดต่อ :${MLMData?.inviteData?.invite_successor?.phone_number}`}</span>
          </div>

          <div className="h-[350px] border  w-full  flex flex-col overflow-y-auto">
            <p>{`Invited Upline (สายชวน) จำนวน ${MLMData?.inviteData?.inviteUplineCount} คน`}</p>
            {MLMData?.inviteData?.users.map((data) => {
              return (
                <div className="flex justify-center items-start ">
                  <div className="flex w-full items-center justify-around h-12 border  ">
                    <img
                      src={data?.profile_image}
                      className="w-8 h-8 rounded-full aspect-square"
                      alt={`LeadUpline-Profile ${data?.uid}`}
                    />
                    <span>{data?.uname}</span>
                    <span>{data?.upline_money_per_person} THB</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>       
        <div className=" h-full flex flex-col justify-start items-center ">
          <div className="w-full h-[150px]  border  flex flex-col justify-center items-center">
            <h3>{`คนซื้อ`}</h3>
            <img
              src={buyerData?.profile_image}
              className="w-16 h-16 rounded-full"
              alt="buyer"
            />
            <span>{buyerData?.uname}</span>
            <span>{`เบอร์โทรติดต่อ :${buyerData?.phone_number}`}</span>
          </div>

          
        </div>         </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5  ">
        <CardOne
          totalPrice={commissionData.totalPrice}
          percentage={commissionData.commissionPercentageBy100}
        />
        <CardTwo
          totalPrice={commissionData.totalPrice}
          percentage={commissionData.commissionPercentageBy100}
        />
        <CardThree
          totalPrice={commissionData.totalPrice}
          percentage={commissionData.commissionPercentageBy100}
        />
        <CardFour
          totalPrice={commissionData.totalPrice}
          percentage={commissionData.commissionPercentageBy100}
        />
        <CardFive
          totalPrice={commissionData.totalPrice}
          percentage={commissionData.commissionPercentageBy100}
        />
      </div>

      <div className="h-[200px] w-full"></div>
    </main>
  );
};

export default Dashboard;
