import { useState, useCallback } from 'react';

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = localStorage.getItem("token");
        const permission = localStorage.getItem("permission");
        return Boolean(token && permission);
    });

    const login = useCallback(() => {
        // Implement your login logic here
        setIsAuthenticated(true);
        // Save token and permission to localStorage if applicable
    }, []);

    const logout = useCallback(() => {
        // Implement your logout logic here
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        window.location.replace('/auth/login');
    }, []);

    const canBypass = (path : any) => {
        // Define paths that can be accessed without authentication
        const bypassPaths = ['/sumary', '/auth/login']; // Add more paths as needed
        return bypassPaths.some(bypassPath => path.startsWith(bypassPath));
    };

    return { isAuthenticated, login, logout, canBypass };
};
