import { motion } from "framer-motion";
import { Link } from "react-router-dom";
interface MenuItems {
  name: string;
  src: string;
  link : string,
}

const ListMenu: MenuItems[] = [
  {
    name: "โพสต์ที่ให้ PostPerty ช่วยขาย",
    src: "https://cdn-icons-png.flaticon.com/512/1378/1378593.png",
    link : "/postperty/help",
  },

  // {
  //   name: "สร้างโพสต์",
  //   src: "https://cdn-icons-png.flaticon.com/512/3032/3032220.png",
  //   link : "/create/select/post",
  // },
  {
    name: "อนุมัติอสังหาฯ",
    src: "https://cdn-icons-png.flaticon.com/512/5442/5442020.png",
    link : "/approved/asset",
  },
  {
    name: "Dashboard",
    src: "https://cdn-icons-png.flaticon.com/512/10397/10397171.png",
    link : "/dashboard",
  },



 
];

export default function MainPageCommission(): JSX.Element {
  return (
    <main className="w-full h-full ">
      <div className="h-full flex ">
        <motion.div
          className="flex flex-col justify-center gap-x-2.5  w-full h-max  p-8   md:flex-row md:mt-36 flex-wrap"
        //   drag="y" // Allow horizontal dragging
        >
          {ListMenu.map((data, index) => {
            return (
                <Link to={`${data.link}`} className="mt-2.5">
                  
              <motion.div
                key={index}
                className="relative flex flex-col justify-center  items-center h-56 mt-8 w-full cursor-pointer hover:bg-gray-100 rounded-2xl  md:mt-0 md:w-72 border"
                whileHover={{ scale: 1.05 }} // Scale on hover
              >
                <img src={data.src} alt={data.name} className="w-24 md:w-36" />
                <h2 className="text-xl mt-2.5 font-main">{data.name}</h2>
              </motion.div>
              </Link>

            );
          })}
        </motion.div>
      </div>
    </main>
  );
}
