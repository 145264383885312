import {
    Card,
    CardBody,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import dayjs from 'dayjs';

// Generate the last 7 days' dates
const dates = Array.from({ length: 7 }, (_, i) => dayjs().subtract(i, 'day').format('MMM DD')).reverse();

// Define the chart options and series separately
const chartOptions: ApexCharts.ApexOptions = {
    chart: {
        toolbar: {
            show: false,
        },
    },
    title: {
        text: "Sales Performance - Last 7 Days",
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#1E3A8A", "#10B981", "#EF4444"], // Different colors for each person
    stroke: {
        lineCap: "round",
        curve: "smooth",
    },
    markers: {
        size: 0,
    },
    xaxis: {
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        labels: {
            style: {
                colors: "#616161",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
            },
        },
        categories: dates, // Last 7 days' dates
    },
    yaxis: {
        labels: {
            style: {
                colors: "#616161",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
            },
        },
    },
    grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
            lines: {
                show: true,
            },
        },
        padding: {
            top: 5,
            right: 20,
        },
    },
    fill: {
        opacity: 0.8,
    },
    tooltip: {
        theme: "dark",
    },
};

// Mockup data for 3 team members
const chartSeries = [
    {
        name: "Alice",
        data: [50, 40, 60, 70, 90, 100, 80],
    },
    {
        name: "Bob",
        data: [30, 50, 80, 60, 70, 110, 90],
    },
    {
        name: "Charlie",
        data: [40, 60, 70, 100, 120, 90, 100],
    },
];

export default function LeadFromTeamOnsite() {
    return (
        <div className="w-10/12 h-screen mx-auto border ">
            <Card>
                <CardBody className="px-2 pb-0">
                    <Chart options={chartOptions} series={chartSeries} type="line" height={630} />
                </CardBody>
            </Card>
        </div>
    );
}
