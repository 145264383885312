import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import "react-datetime/css/react-datetime.css";
import Swal from "sweetalert2";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 13.817948797095687,
  lng: 100.04449978134642,
};

// Task types array
const taskTypes = [
  "เข้าเซ็นต์สัญญา",
  "เข้าถ่ายภาพ/วีดิโอ",
  "เข้าติดป้ายประกาศในพื้นที่",
  "เข้าทำความสะอาดบ้าน/ดูแลความเรียบร้อย",
  "เปิดบ้านให้เข้าชม",
  "นัดรับเอกสารเพิ่มเติมหรือกุญแจบ้าน",
  "ปรึกษาสินเชื่อ/ยื่นกู้",
  "ธนาคารเข้าประเมินบ้าน",
  "ยื่นไถ่ถอน",
  "ธุรกรรมที่ดิน",
  "ธุรกรรมอื่นๆ (น้ำ/ไฟฟ้า)",
  "ติดต่อคุยรายละเอียด",
];

const OnsiteWorkFlow: React.FC = React.memo(() => {
  interface OnsiteMembers {
    uid: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    profile_image: string;
    uname: string;
    zone: string;
  }

  interface LeadMarkers {
    location: {
      lat: number;
      lng: number;
    };
    type: string;
    official: boolean;
    id: string;
    imperfect_asset: boolean;
    sold: boolean;
  }

  interface LeadDataDisplay {
    id: string;
    title: string;
    description: string;
    images_url: string[];
    type: string;
    leadUser: {
      uname: string;
      profile_image: string;
      role: string;
      enable: boolean;
    };
    leadTargetData: {
      phone_number: string;
      description: string;
      date: string;
      status: string;
    };
  }

  interface oldMarkerLocation {
    lat : number;
    lng : number;
  }

  const token = localStorage.getItem("token");
  const [isLoaded, setIsLoaded] = useState(false);
  const [onsiteMembers, setOnsiteMembers] = useState<OnsiteMembers[]>([]);
  const [leadMarkers, setLeadMarkers] = useState<LeadMarkers[]>([]);
  const [leadDataDisplay, setLeadDataDisplay] = useState<LeadDataDisplay>({
    id: "",
    title: "",
    description: "",
    images_url: ["https://i.ytimg.com/vi/9nAt_o3ypL4/maxresdefault.jpg"],
    type: "",
    leadUser: {
      uname: "",
      profile_image: "",
      role: "",
      enable: false,
    },
    leadTargetData: {
      phone_number: "",
      description: "",
      date: "",
      status: "",
    },
  });
  // Send to Backend
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [notes, setNotes] = useState("");
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
    undefined
  );
  const [latAdjust, setLatAdjust] = useState<string>("");
  const [lngAdjust, setLngAdjust] = useState<string>("");
  const [selectedOnsite, setSelectedOnsite] = useState("");
  const [oldMarkerLocation , setOldMarkerLocation] = useState<oldMarkerLocation>();
  const [IdMarker , setIdMarker] = useState('');
  const [phoneNumberLeadForOnsite , setPhoneNumberLeadForOnsite] = useState('')
  // Send to Backend
  useEffect(()=>{
    setPhoneNumberLeadForOnsite(leadDataDisplay?.leadTargetData?.phone_number)
  },[leadDataDisplay])
  console.log('phoneLead' , phoneNumberLeadForOnsite)
  const { isLoaded: apiIsLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });

  function getDetailLead(aid: string) {
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_API}/map/markerData`,
        {
          aid: aid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res: any) => {
        setLeadDataDisplay(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const handleMarkerClick = useCallback((id: string , oldMarkerLocation : any) => {
    setOldMarkerLocation(oldMarkerLocation);
    setIdMarker(id);
    getDetailLead(id);
  }, []);

  console.log("old mark" , oldMarkerLocation)
  const handleMapLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const mapOptions = {
    zoomControl: true,
    gestureHandling: "greedy", // or 'cooperative', 'greedy', 'none'
  };

  function getLeadMarkers() {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/map/leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setLeadMarkers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getOnsiteMembers() {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/agent/onsite-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        console.log("onsite profile" , res.data);
        setOnsiteMembers(res?.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const handleTaskChange = (task: string) => {
    setSelectedTasks((prev) =>
      prev.includes(task) ? prev.filter((t) => t !== task) : [...prev, task]
    );
  };

  const handleDateChange = (date: string | Moment | Date) => {
    if (moment.isMoment(date) || date instanceof Date) {
      setSelectedDate(moment(date));
    } else {
      setSelectedDate(undefined);
    }
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLatAdjust(e.target.value);
  };

  const handleLngChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLngAdjust(e.target.value);
  };

  const saveData = () => {
    if (selectedDate) {
      const timestamp = selectedDate.valueOf(); // Convert to timestamp
      const lat = parseFloat(latAdjust);
      const lng = parseFloat(lngAdjust);

      const dataToSave = {
        asset_id : IdMarker,
        tasks: selectedTasks,
        notes:  notes,
        date : timestamp,
        lat: isNaN(lat) ? oldMarkerLocation?.lat : lat,
        lng: isNaN(lng) ? oldMarkerLocation?.lng : lng,
        onsite_uid: selectedOnsite,
        phoneNumberLead : phoneNumberLeadForOnsite,
      }; 


      // Send dataToSave to your backend using an API call
      axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/assignTask`, dataToSave, {
        headers :{
          Authorization : `Bearer ${token}`
        }
      }).then(response => {
        Swal.fire(`ข้อมูลเรีบบร้อย` , 'บันทึกข้อมูลเสร็จสิ้น' , 'success')
        console.log(response)
      }).catch(error => {
        Swal.fire(`${error.response.data.Error}` , '' , 'error')
        console.error('Error saving data:', error);
      });
    } else {
      console.log("Please select a date and time.");
    }
  };

  useEffect(() => {
    getLeadMarkers();
    getOnsiteMembers();
  }, []);

  return (
    <main className="grid grid-cols-2 w-full max-h-screen">
       {(!apiIsLoaded || !isLoaded) && (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
            กำลังโหลดข้อมูล..
          </div>
        )}
      <div className="flex flex-col w-full h-full">
        <div className="w-full h-full">
        {apiIsLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={10}
            onLoad={handleMapLoad}
            options={mapOptions}
          >
            {leadMarkers.map((marker) => (
              <Marker
                key={marker.id}
                position={marker.location}
                onClick={() => handleMarkerClick(marker.id , marker.location)}
              />
            ))}
          </GoogleMap>
          
          
          
        )}
        </div>
        <main className=" z-50 w-full h-10/12 overflow-y-auto border overflow-hidden  boder-red-500">
          <div className="w-full h-max flex flex-col bg-white  overflow-y-auto ">
            {onsiteMembers.map((member, idx) => (
              <div
                key={idx}
                onClick={() => setSelectedOnsite(member.uid)}
                className={`relative w-full flex p-2.5 items-center h-40 border ${
                  selectedOnsite === member.uid
                    ? "bg-postperty text-white cursor-not-allowed"
                    : "cursor-pointer"
                }`}
              >
                <img
                  src={member.profile_image}
                  alt="profile-onsite"
                  className="w-24 h-24 mb-2.5"
                />
                <span className="absolute left-3 bottom-1">
                  {member.phone_number}
                </span>
                <span className="absolute bottom-1 right-3 uppercase">
                  {member.zone}
                </span>
                <div className="w-full h-full flex flex-col justify-center text-start mb-2.5">
                  <div className="flex flex-col ml-2.5 justify-center w-full h-full">
                    <span className="">{member.uname}</span>
                    <div className="flex w-full h-auto">
                      (<p>{member.first_name}</p>
                      <p className="ml-2.5">{member.last_name}</p>)
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
      <div className="flex flex-col max-h-screen overflow-hidden">
        <main className="w-full h-full border  flex flex-col max-h-screen overflow-hidden">
          <div className="border w-full grid grid-cols-2 h-auto">
            <img
              className="w-full max-w-[450px] mx-auto max-h-[350px] object-contain"
              src={leadDataDisplay?.images_url[0]}
              alt="lead image"
            />
            <div className="w-full h-max p-2.5">
              <div className="w-full h-auto p-2.5 border rounded-xl">
                <span className="text-l font-bold">1.ข้อมูลทั่วไป</span>
                <p>Lead ID : {leadDataDisplay.id}</p>
                <p>รายละเอียด : {leadDataDisplay.description}</p>
                <p>
                  เบอร์โทร (Lead): {leadDataDisplay?.leadTargetData?.phone_number}
                </p>
              </div>
              <div className="border rounded-xl w-full max-h-full flex flex-col mt-2.5 p-2.5">
                <h2 className="font-bold">2.ข้อมูลคน Lead</h2>
                <img
                  src={leadDataDisplay.leadUser.profile_image}
                  className="rounded-full w-36 h-36 mx-auto"
                />
                <p>User : {leadDataDisplay.leadUser.uname}</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 w-full h-full  p-2  overflow-y-auto">
            <span className="text-l font-bold col-span-12  ">
              2.ประเภทการมอบหมายงาน
            </span>
            <div className="col-span-6 w-full h-full border p-2.5">
              {taskTypes.map((task, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`task-${index}`}
                    value={task}
                    checked={selectedTasks.includes(task)}
                    onChange={() => handleTaskChange(task)}
                    className="mr-2"
                  />
                  <label htmlFor={`task-${index}`}>{task}</label>
                </div>
              ))}
            </div>

            <div className="col-span-6 w-full h-full border p-2.5">
              <h2 className="mt-4 text-red-500">วันที่และเวลา *จำเป็นต้องระบุ</h2>
              <Datetime
                value={selectedDate}
                onChange={handleDateChange}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                inputProps={{ placeholder: "เลือกวันที่และเวลา" }}
                className="w-full border p-2 mt-2 outline-0 rounded-full"
              />
              <h2>หมายเหตุ</h2>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full h-24 border p-2 outline-0 rounded-xl"
              />

              <div className="mb-2">
                <label htmlFor="lat-adjust" className="mr-2">
                เบอร์โทรให้ onsite ติดต่อลูกค้า
                </label>
                <input
                  type="text"
                  id="phoneNumberLeadForOnsite"
                  value={phoneNumberLeadForOnsite}
                  onChange={(e: any) => setPhoneNumberLeadForOnsite(e.target.value)}
                  className="border p-2 w-full rounded-full outline-0 text-center text-black"
                />
              </div>
              <h2 className="mt-4 w-full text-center">แก้ไขตำแหน่งที่ตั้งใหม่</h2>
              <div className="mb-2">
                <label htmlFor="lat-adjust" className="mr-2">
                  ละติจูด:
                </label>
                <input
                  type="text"
                  id="lat-adjust"
                  value={latAdjust}
                  onChange={handleLatChange}
                  className="border p-2 w-full rounded-full outline-0 text-center"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="lng-adjust" className="mr-2">
                  ลองจิจูด:
                </label>
                <input
                  type="text"
                  id="lng-adjust"
                  value={lngAdjust}
                  onChange={handleLngChange}
                  className="border p-2 w-full rounded-full outline-0 text-center"
                />
              </div>
              <button onClick={saveData} className="bg-blue-500 text-white p-2 rounded-full mt-4 w-full">
                Save Data
              </button>
            </div>
          </div>
        </main>
        
      </div>
    </main>
  );
});

export default OnsiteWorkFlow;
