import { Cog6ToothIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";

const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Monitored",
    value: "monitored",
  },
  {
    label: "Unmonitored",
    value: "unmonitored",
  },
];

const TABLE_HEAD = [
  "ข้อมูลสมาชิก",
  "จำนวนเงิน",
  "ข้อมูลติดต่อ",
  "สถานะ",
  "วันที่สมัครสมาชิก",
  "ดูข้อมูลสมาชิก",
];

export function TableMembers() {
  const [TABLE_ROWS, setTABLE_ROWS] = useState([]);
  console.log(TABLE_ROWS)
  const [dataSearchUser, setDataSearchUser] = useState<string>('');
  useEffect(() => {
    getMembers();
  }, []);
  function getMembers() {
    try {
      axios
        .get(`${process.env.REACT_APP_ADMIN_API}/fetchData/allUsers?page=1`)
        .then((res) => {
          console.log(res.data);
          const data = res.data;
          setTABLE_ROWS(data);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function convertISOToDDMMYYYY(isoDate: string) {
    const date = new Date(isoDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();

    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    return formattedDate;
  }

  function handleSearchUser(e: any) {
    const value = e.target.value;
    setDataSearchUser(value);
  }

  function handleConfirmClickSearchUser() {
    try {
      axios
        .get(
          `${process.env.REACT_APP_ADMIN_API}/fetchData/allUsers?page=1&keyword=${dataSearchUser}`
        )
        .then((res) => {
          console.log(res.data);
          const data = res.data;
          setTABLE_ROWS(data);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length != 10) {
      return phoneNumber;
    }
    // Ensure the input is a 10-digit number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    if (numericPhoneNumber.length !== 10) {
      console.error("Invalid phone number");
      return phoneNumber;
    }

    // Format the phone number
    const formattedPhoneNumber = `${numericPhoneNumber.slice(
      0,
      3
    )}-${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;
    return formattedPhoneNumber;
  }


  function handleEditMember(uid : string){
      window.location.replace(`/edit/member/${uid}`)
  }
  return (
    <Card className="h-full w-full mt-24 font-main">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex flex-col items-center justify-between gap-8  md:flex-row">
          <div>
            <Typography variant="h5" color="blue-gray" className="font-main">
              สมาชิกทั้งหมด
            </Typography>
            <Typography color="gray" className="mt-1 font-main font-medium">
              โปรดทำตามนโยบายของบริษัทและรักษาความลับของลูกค้าอย่างสูงสุด
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <input
              type="text"
              placeholder="ค้นหาผู้ใช้"
              className="border outline-0 rounded-full text-center h-12 "
              onChange={handleSearchUser}
            />
            <button
              className="border  ml-2.5 w-36 rounded-full h-12 bg-black text-white "
              onClick={() => handleConfirmClickSearchUser()}
            >
              ค้นหา
            </button>
          </div>
          {/* <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            <Button variant="outlined" size="sm">
              view all
            </Button>
            <Button className="flex items-center gap-3" size="sm">
              <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add member
            </Button>
          </div> */}
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          {/* <Tabs value="all" className="w-full md:w-max">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs> */}
          {/* <div className="w-full md:w-72">
            <Input
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            />
          </div> */}
        </div>
      </CardHeader>
      <CardBody className="overflow-y-auto h-[690px] px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 font"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70 font-main"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(
              (
                {
                  profile_image,
                  uname,
                  phone_number,
                  first_name,
                  enable,
                  date_created,
                  uid,
                  point
                },
                index
              ) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={uname}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Avatar src={profile_image} alt={uname} size="sm" />
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal font-main"
                          >
                            {uname}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70 font-main"
                          >
                            {first_name}
                          </Typography>
                        </div>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal font-main"
                        >
                          {point} THB
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70 font-main"
                        >
                          {enable}
                        </Typography>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal font-main"
                        >
                          {formatPhoneNumber(phone_number)}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70 font-main"
                        >
                          {enable}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          variant="ghost"
                          size="sm"
                          value={enable ? "Active" : "Banned"}
                          color={enable ? "green" : "red"}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-main"
                      >
                        {convertISOToDDMMYYYY(date_created)}
                      </Typography>
                    </td>
                    <td className={classes} onClick={()=>handleEditMember(uid)}>
                      <Tooltip content="Edit User">
                        <IconButton variant="text">
                          <Cog6ToothIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      {/* <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 of 10
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            Previous
          </Button>
          <Button variant="outlined" size="sm">
            Next
          </Button>
        </div>
      </CardFooter> */}
    </Card>
  );
}
