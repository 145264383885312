import React from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Swal from 'sweetalert2';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface FileDetail {
    url: string;
    type_work: string;
    created_date: string;
    status: string;
    asset_id: string;
}

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    files: FileDetail[];
    assetId: string;
}

const token = localStorage.getItem("token");

async function handleConfirmDocument(assetId: string) {
    console.log(assetId);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/onsite/confirm/document`, { assetId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        Swal.fire('Confirm เอกสารเรียบร้อย', `${res.data.message}`, 'success');
    } catch (error: any) {
        Swal.fire(`ไม่สามารถติดต่อ Server ได้`, `${error.response.data.Error}`, 'error');
    }
}

async function downloadFilesFromBackend(files: FileDetail[] , assetId : string) {
    const fileUrls = files.map(file => file.url);
    const response = await fetch(`${process.env.REACT_APP_ADMIN_API}/proxy/download-files-s3?files=${fileUrls.join(',')}&zipName=${assetId}`);
    
    if (!response.ok) {
        throw new Error('Failed to download files');
    }

    const blob = await response.blob();
    saveAs(blob, `${assetId}`);
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, files, assetId }) => {
    if (!isOpen) return null;

    const firstFile = files[0];
    const typeWork = firstFile ? firstFile.type_work : '';
    const createdDate = firstFile ? firstFile.created_date : '';
    const status = firstFile ? firstFile.status : '';
    const fileAssetId = firstFile ? firstFile.asset_id : '';

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
            <motion.div
                initial={{ y: "-50%", opacity: 0 }}
                animate={{ y: "0%", opacity: 1 }}
                exit={{ y: "50%", opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="bg-white p-4 rounded-lg max-w-lg w-full relative overflow-y-auto"
            >
                <h2 className="text-lg font-bold mb-2">Files for {assetId}</h2>
                <button className="absolute top-4 right-4 text-gray-500" onClick={onClose}>
                    X
                </button>
                {firstFile && (
                    <div className="mb-4 text-sm">
                        <p><strong>Type of Work:</strong> {typeWork}</p>
                        <p><strong>Created Date:</strong> {createdDate}</p>
                        <p><strong>Status:</strong> {status}</p>
                        <p><strong>Asset ID:</strong> {fileAssetId}</p>
                    </div>
                )}
                <ul>
                    {files.map((file, index) => (
                        <li key={index} className="mb-4">
                            <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                {file.url}
                            </a>
                        </li>
                    ))}
                </ul>
                <button
                    onClick={() => downloadFilesFromBackend(files , assetId)}
                    className="w-full h-12 bg-postperty rounded-full flex justify-center items-center text-white cursor-pointer mb-4"
                >
                    Download All as Zip
                </button>
                <div
                    onClick={() => handleConfirmDocument(assetId)}
                    className="w-full h-12 bg-red-500 rounded-full flex justify-center items-center text-white cursor-pointer"
                >
                    Confirm เอกสาร
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Modal;
